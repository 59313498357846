"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
const HttpService_1 = require("./HttpService");
class AuthService extends HttpService_1.HttpService {
    constructor() {
        super(...arguments);
        this.getUser = (id) => {
            return this.get(`/api/users/${id}`, { timeout: 5000 });
        };
        this.me = async () => {
            let user = await this.get(`/api/users/me`, { timeout: 5000 });
            await this.setCurrentUserId(user.id);
            return user;
        };
        this.getUsers = () => {
            return this.get(`/api/users`, { maxCacheMinutes: 30 });
        };
        this.setAuthCredentials = (token) => {
            return HttpService_1.HttpService.storage.setItem('token', token.token);
        };
        this.setCurrentUserId = (id) => {
            return HttpService_1.HttpService.storage.setItem('userId', id.toString());
        };
        this.login = (email, password) => {
            return this.post(`/api/auth/login`, { email, password });
        };
        this.register = (email, password, extras) => {
            return this.post(`/api/auth/register`, { email, password, extras });
        };
        this.logout = () => {
            this.resetCache();
            return HttpService_1.HttpService.storage.removeItem('token');
        };
        this.blockUser = (userId) => {
            return this.post(`/api/users/block/${userId}`, {});
        };
        this.reportUser = (userId, description) => {
            return this.post(`/api/users/report/${userId}`, { description });
        };
        this.initializeResetPassword = (email) => {
            return this.post(`/api/auth/trigger-reset-password`, { email });
        };
        this.resetPassword = (email, secret, password) => {
            return this.post(`/api/auth/reset-password`, { email, secret, password });
        };
    }
}
exports.AuthService = AuthService;
