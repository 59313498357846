import "./LoggedOutMobileMenu.scss";

import * as React from 'react';
import {
    IonContent,
    IonIcon,
    IonLabel,
    IonMenu,
} from "@ionic/react";
import {logInOutline} from "ionicons/icons";
import Logo from "../../assets/Logo/logo-rectangle-pink.png";
import { Link } from "react-router-dom";


export const LoggedOutMobileMenu: React.FC = () => (
  <IonMenu className="logged-out-mobile-menu-component" menuId="logged-out-menu" contentId="logged-out-menu-button">
      <div className="wrapper">

          <img className="logo" src={Logo} />

          <Link className="link" to="/register">
              Join For Free
          </Link>

          <Link className="link" to="/login">
              Log In
          </Link>
      </div>
  </IonMenu>
);
