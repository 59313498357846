"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_IMAGE_SIZE_BYTES = exports.getMobileOperatingSystem = exports.HttpService = exports.HttpServiceHelper = exports.AuthService = exports.HttpApiService = exports.ProfileService = exports.MessageService = exports.CoinTransactionService = exports.RadarService = void 0;
const MessageService_1 = require("./services/MessageService");
const HttpApiService_1 = require("./services/HttpApiService");
const AuthService_1 = require("./services/AuthService");
const HttpService_1 = require("./services/HttpService");
const HttpServiceHelper_1 = require("./services/HttpServiceHelper");
const CoinTransactionService_1 = require("./services/CoinTransactionService");
const RadarService_1 = require("./services/RadarService");
const Utilities_1 = require("./lib/Utilities");
exports.RadarService = new RadarService_1.RadarService();
exports.CoinTransactionService = new CoinTransactionService_1.CoinTransactionService();
exports.MessageService = new MessageService_1.MessageService();
exports.ProfileService = new HttpApiService_1.HttpApiService();
exports.HttpApiService = new HttpApiService_1.HttpApiService();
exports.AuthService = new AuthService_1.AuthService();
exports.HttpServiceHelper = HttpServiceHelper_1.HttpServiceHelper;
exports.HttpService = HttpService_1.HttpService;
exports.getMobileOperatingSystem = Utilities_1.getMobileOperatingSystem;
exports.MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024; //5 MB
