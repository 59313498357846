import * as React from "react";
import {useEffect, useState} from "react";
import "./LoggedOutHomePage.scss";
import {IonContent, IonPage} from "@ionic/react";
import {MojitoButton} from "../../components/MojitoButton/MojitoButton";

interface Props {

}

export const LoggedOutHomePageApp: React.FC<Props> = (props: Props) => {

    const [value, setValue] = useState<any>(null);

    useEffect(() => {

    }, []);

    return (
      <IonPage className="logged-out-home-page">
          <IonContent>
              <MojitoButton url="/register" label="Register" />
              <MojitoButton url="/login" label="Login" />
          </IonContent>
      </IonPage>
    )
}
