"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpApiService = void 0;
const HttpService_1 = require("./HttpService");
class HttpApiService extends HttpService_1.HttpService {
    constructor() {
        super(...arguments);
        this.me = () => {
            return this.get(`/api/profiles/me`);
        };
        this.getProfile = (userId) => {
            return this.get(`/api/profiles/${userId}`);
        };
        this.getUsers = () => {
            return this.get(`/api/users`, { maxCacheMinutes: 10 });
        };
        this.updateProfile = (profile) => {
            return this.put(`/api/profiles`, profile);
        };
        this.createProfilePicture = (base64EncodedImage) => {
            return this.post(`/api/pictures`, { data: base64EncodedImage }, { timeout: 60000 });
        };
        this.deleteProfilePicture = (id) => {
            return this.delete(`/api/pictures/${id}`);
        };
        this.orderProfilePictures = (ids) => {
            return this.put(`/api/pictures/order-pictures`, { ids: ids });
        };
        this.joinGroup = (groupId) => {
            return this.post(`/api/groups/join`, { group_id: groupId });
        };
        this.getGroups = () => {
            return this.get(`/api/groups`, { maxCacheMinutes: 30 });
        };
        this.getGroupInfoForUser = (groupId) => {
            return this.get(`/api/groups/${groupId}`);
        };
        this.getGroupPromptsForUser = (groupId) => {
            return this.get(`/api/groups/${groupId}/prompts`);
        };
        this.getGroupPostsForUser = (groupId) => {
            return this.get(`/api/groups/${groupId}/posts`);
        };
        this.getPromptInfoForUser = (promptId) => {
            return this.get(`/api/prompts/${promptId}`);
        };
        this.savePromptResponse = (promptId, body) => {
            return this.put(`/api/prompts/${promptId}/responses`, {
                response: body,
                promptId
            });
        };
        // General Picture Upload
        this.savePicture = (userId, base64EncodedImage, rectangle) => {
            return this.post(`/api/users/${userId}/pictures`, { data: base64EncodedImage, rectangle }, { timeout: 60000 });
        };
        // Articles
        this.getArticles = () => {
            return this.get(`/api/articles`);
        };
        this.createArticle = (article) => {
            return this.post(`/api/articles`, article);
        };
        this.getArticle = (id) => {
            return this.get(`/api/articles/${id}`);
        };
        this.likeContent = (entityId, entityType) => {
            return this.post(`/api/user-likes`, {
                entityId, entityType
            });
        };
        this.unlikeContent = (likeId) => {
            return this.delete(`/api/user-likes/${likeId}`);
        };
        this.toggleLikeContent = (entityId, entityType) => {
            return this.post(`/api/user-likes/toggle`, {
                entityId, entityType
            });
        };
        this.getUserLikes = () => {
            return this.get(`/api/user-likes`);
        };
        // Home content
        this.getHomeLatestPosts = () => {
            return this.get(`/api/content/home/latest-posts`);
        };
    }
    checkProfileCompletion(user) {
        if (user.pictures.length >= 3 && user.profile.first_name && user.profile.city && user.profile.dob &&
            user.profile.education && user.profile.zipcode) {
            return true;
        }
        return false;
    }
    getTotalCoins(user) {
        return user.transactions.map(t => t.amount).reduce((total, current) => total + current, 0);
    }
}
exports.HttpApiService = HttpApiService;
