import * as React from "react";
import "./LoggedOutWebFooter.scss";
import { Link } from "react-router-dom";
import {Logo} from "../../Logo/Logo";


export const LoggedOutWebFooter: React.FC = () => (
  <div className="logged-out-web-footer">
      <div className="row">
          <div className="left-column">
              <Logo />
          </div>
          <div className="right-column">
                  <a href="mailto:info@mojitodating.com">Contact</a>
                  <Link to="/privacy">Privacy Policy</Link>
                  <Link to="/terms-of-use">Terms of Use</Link>
          </div>
      </div>
      <div className="copyright">
        Copyright 2023 by Mojito Dating.  <br />
        Mojito is a trademark of CTO Nexus LLC. All rights reserved.
      </div>
  </div>
)
