import { IonReactRouter } from '@ionic/react-router';
import * as React from "react";
import {LoggedOutNavigation} from "./LoggedOutNavigation";

export const Navigation: React.FC = () => {
    return(
      <IonReactRouter>
          <LoggedOutNavigation />
      </IonReactRouter>
    );
};
