import "./LoggedOutHomePageWeb.scss";
import * as React from "react";
import {Helmet} from "react-helmet";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import {HomePageBanner} from "../../components/HomePageBanner/HomePageBanner";
import {LoggedOutWebNavigationBar} from "../../components/LoggedOutHomePage/LoggedOutWebNavigationBar/LoggedOutWebNavigationBar";
import {LoggedOutMobileMenu} from "../../components/LoggedOutHomePage/LoggedOutMobileMenu";
import {LoggedOutWebFooter} from "../../components/LoggedOutHomePage/LoggedOutWebFooter/LoggedOutWebFooter";
import {useLocation} from "react-router";
import {AppStoreButton} from "../../components/Common/AppStoreButton";


export const LoggedOutHomePageWeb: React.FC = () => {


    return (
      <IonPage className="logged-out-home-page-web">
          <Helmet>
              <title>Mojito: The Latin Flavored Dating App</title>
          </Helmet>

          <LoggedOutMobileMenu />

          <IonContent>

              { false && <LoggedOutWebNavigationBar /> }

              <HomePageBanner />

              <div className="service-section">
                  <div className="title">
                      A refreshing mix of people who love Latin culture
                  </div>

                  <p>
                      We believe that culture, personality and values are the best indicators of romantic compatibility.
                      Mojito Dating is on a mission to bring people together based on their common appreciation for Latin
                      culture, regardless of race and nationality.
                  </p>


              </div>

              <div className="feature-box-section mojito-lounge-right">
                  <div className="row">
                      <div className="col">
                          <div className="mojito-feature-box">
                              <div className="content">
                                  <h2>Less Swiping. More Dancing.</h2>
                                  <p>
                                      We believe genuine connections happen through common interests.
                                      You will spend less time swiping and more time connecting in real life.
                                  </p>
                                  <AppStoreButton />
                              </div>
                          </div>
                      </div>
                      <div className="col">
                          <div className="imagebox right couple-hugging" />
                      </div>
                  </div>
              </div>

              <div className="feature-box-section mojito-lounge-left icn-pattern icn-pattern-right">
                  <div className="row">
                      <div className="col">
                          <div className="imagebox left people-dancing" />
                      </div>
                      <div className="col">
                          <div className="mojito-feature-box">
                              <div className="content">
                                  <h2>Social Networking</h2>
                                  <p>
                                      Our app includes a social network experience featuring daily icebreaker discussions,
                                      polls and other activities that help you connect with other users based on your opinions
                                      and interests.
                                  </p>
                                  <AppStoreButton />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="mojito-invitation-section text-center color-white">
                  <div className="mojito-container">
                      <div className="mojito-invitation-wrapper">
                          <div className="mojito-main-title">
                              <h2 className="h2">Get Started</h2>
                              <p>Join today and become part of the most vibrant community of singles in the world.</p>
                          </div>
                          <div>
                              <AppStoreButton />
                          </div>
                      </div>
                  </div>
              </div>

              <LoggedOutWebFooter />
          </IonContent>
      </IonPage>
    )
}
