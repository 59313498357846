"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoinTransactionService = void 0;
const HttpService_1 = require("./HttpService");
class CoinTransactionService extends HttpService_1.HttpService {
    constructor() {
        super(...arguments);
        this.processTransaction = (code, otherUserId) => {
            return this.post(`/api/user/coinTransaction`, { code, otherUserId });
        };
    }
}
exports.CoinTransactionService = CoinTransactionService;
