"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpServiceHelper = void 0;
class HttpServiceHelper {
}
exports.HttpServiceHelper = HttpServiceHelper;
HttpServiceHelper.getErrorHandler = (handlers, defaultHandler) => {
    return (error) => {
        if (error.statusCode && handlers[error.statusCode]) {
            return handlers[error.statusCode](error);
        }
        else {
            return defaultHandler(error);
        }
    };
};
HttpServiceHelper.errorFormatter = (error) => {
    //console.log(error);
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        return Promise.reject(error.response.data);
    }
    else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log('Error', error.request);
        return Promise.reject({
            statusCode: 500,
            error: 'Internal Server Error'
        });
    }
    else {
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
        return Promise.reject({
            statusCode: 500,
            error: 'Internal Server Error'
        });
    }
};
