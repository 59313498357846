import * as React from 'react';
import {CSSProperties} from "react";
import { Helmet } from "react-helmet";
import {IonContent, IonPage} from "@ionic/react";

const globalStyle: CSSProperties = {
    padding: 20,
    listStyle: 'circle'
};

export default class PrivacyPage extends  React.Component {
    render() {
        return(
            <IonPage>
            <IonContent>
            <Helmet>
                <title>Mojito: Privacy Policy</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
                      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
                      crossOrigin="anonymous" />
            </Helmet>
            <div style={globalStyle}>

                <h1>Mojito | Privacy Policy</h1>

                <h2>Introduction</h2>

                <p><span >Your privacy is important to CTO Nexus Latam LLC, the makers of Mojito (the “Company”, “we”, “us” or “our”). To better protect your privacy, we provide this statement explaining our online information practices, and the choices you can make about the way your information is collected and used on the Company’s </span><span
                    >mobile application and related website each commonly known as “Mojito” (collectively, the “App”)</span><span
                    >.</span></p>
                <p><span >This policy regarding our privacy practices (the “Privacy Policy”) describes how we treat the information we or our strategic partners collect when you visit and/or use the App or place an Order. When doing so, you also consent to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy – please read it carefully.</span>
                </p>
                <p><span >Capitalized terms used but not otherwise defined in this Privacy Policy have the meanings set forth in the accompanying Terms of Use.</span>
                </p>
                <p><span >This Privacy Policy contains information concerning the following topics, among others:</span>
                </p>
                <ul>
                    <li>
                        What personally identifiable information (“PII”) is collected on the App and/or through your placing of an order for any Service (and “Order”), and how the same may be used
                    </li>
                    <li>Sharing of PII with third parties </li>
                    <li>Use of tracking technology</li>
                    <li>Supplementing of PII with information from other sources</li>
                    <li>How you can change PII you have provided</li>
                    <li>Security measures in place</li>
                </ul>
                <p><span >Notice of all changes that materially affect ways in which PII may be used or shared will be posted in updates to the Company’s Privacy Policy, and continued use of the App shall be deemed your consent to any such changes.</span>
                </p>
                <h2>Information Collection and Use</h2>
                <p><span >Depending upon your reason for using the App, we may ask you for certain PII, such as your name, email address and/or telephone number. &nbsp;</span><span
                    >Individuals who wish to register for one or more of the Company’s offerings shall be required to provide more PII (see registration form for details). &nbsp;</span>
                </p>
                <p><span >We may use your PII, for example, to:</span></p>
                <ul>
                    <li ><span >Communicate with you regarding your request for any of the Company’s public resources, your registration for any of our offerings, or your request to receive our newsletter.</span>
                    </li>
                    <li ><span >Conduct audits, data analysis and research </span>
                    </li>
                    <li ><span >Analyze, customize and improve our products and services.</span>
                    </li>
                    <li ><span >Provide customer support</span></li>
                    <li ><span >Enforce our Terms of Use</span></li>
                </ul>
                <p><span >We sometimes collect data that, in and of itself, does not directly identify you. &nbsp;We may collect, use, disclose and transfer this non-personally identifiable information for any purpose. &nbsp;For example, we or our strategic partners may collect information about your visits to the App, the links and advertisements that you click on and the URL that referred you to the App. &nbsp;</span>
                </p>
                <p><span >If you pay for any of the goods or services offered on the App by credit card, your credit card information will be submitted through a plug-in application from a third-party gateway provider (such as Paypal), which third-party will store such data for processing payment for your Order, and we may store such data for use in future Orders. &nbsp;The Company shall not have access to your full credit card information.</span>
                </p>
                <p><span >We may disclose PII in response to legal process (for example, in response to a court order or a subpoena). &nbsp;We also may disclose such information in response to a law enforcement agency’s request, or where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, to verify or enforce compliance with the policies governing our App and applicable laws or as otherwise required or permitted by law or consistent with legal requirements. In addition, we may transfer PII about you if we, or one of our business units, are acquired by, sold to, or merged with another company.</span>
                </p>
                <p><span >You can change your PII by emailing us at </span><a
                    href="mailto:ricardo@ctonexus.com"><span
                    >ricardo@ctonexus.com</span></a><span > or through your account on the App.</span>
                </p>
                <h2>Device Information</h2>
                <p><span >When you use a mobile device like a smartphone to access our App, we may access, collect, monitor or store information on your device. Device information could be small data files or similar data structures stored on or associated with your mobile device, which uniquely identifies your mobile device. Device information may be data stored in connection with the device hardware, data stored in connection with the device’s operating system or other software, or data sent to the device by the Company.</span>
                </p>
                <p><span >Device information may be delivered to us or to a one or more of our strategic partners about how you browse and use the App. Some features of the App may not function properly if use or availability of device information is impaired or disabled.</span>
                </p>
                <h2>Security of Information</h2>
                <p><span >We have put in place appropriate, industry-standard physical, electronic and managerial procedures to safeguard and help prevent unauthorized access, maintain data security, and correctly use the information we collect online.</span>
                </p>
                <h2>Location Information</h2>
                <p><span >In some cases, we may collect and store information about where you are located, such as by converting your IP Address into a geolocation or utilizing the location services of our strategic partners. We may also ask you to provide information about your location, for example to use your geolocation information from your mobile device to geotag a post. We may use location information to improve and personalize services for you.</span>
                </p>
                <h2>Cookies and Log Files</h2>
                <p><span >We may use cookies and web log files to track usage of the App to facilitate access to the App and to personalize your online experience. &nbsp;A cookie is a tiny data file which resides on your computer which allows the Company to recognize you as a user when you return to the App using the same computer and web browser. &nbsp;Through the use of a cookie, we also may automatically collect information about your online and/or mobile activity and/or behavior, such as the web pages you visit, the links you click, and the searches you conduct. &nbsp;Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered. &nbsp;As with information you enter at registration, cookie and log file data is used to customize your experience. </span>
                </p>
                <p><span >One type of cookie, known as a “persistent” cookie, is set once you’ve logged into the App. &nbsp;The next time you visit the App, the persistent cookie will allow the Company to identify you as an existing user so you will not need to log in before using the service. &nbsp;Another type of cookie, called a “session” cookie, is used to identify a particular visit to the App. Session cookies expire after a short time or when you close your web browser.</span>
                </p>
                <p><span >Due to the communications standards on the internet, when you visit the App we automatically receive the URL of the site from which you came and the site to which you are going when you leave the App. &nbsp;We also receive the internet protocol (IP) address of your computer (or the proxy server you use to access the World Wide Web), your computer operating system and type of web browser you are using, as well as the name of your internet service provider (ISP). &nbsp;This information is used to analyze overall trends to help us improve the App and the products and services we offer to you and like-minded consumers. The linkage between your IP address and your personally identifiable is never shared with third-parties without your permission or except when required by law.</span>
                </p>
                <h2>Analytics Information</h2>
                <p><span >We use third-party analytics tools to help us measure traffic and usage trends and online behavior. These tools collect information sent by your computer or mobile device or our App, including the web pages you visit, add-ons, and other information that assists us in improving the App. We collect and use this analytics information with analytics information from other users so that it cannot reasonably be used to identify any particular individual user.</span>
                </p>
                <h2>Do Not Track</h2>
                <p><span >Some internet browsers have incorporated “Do Not Track”</span> <span
                    >(DNT) technologies that can send a signal to the websites that you visit indicating you do not wish to be tracked. &nbsp;We do not currently respond to browser DNT signals.</span>
                </p>
                <h2>Surveys</h2>
                <p><span >From time to time, the Company may conduct user surveys. &nbsp;We may share any information gathered in these surveys with strategic partners.</span>
                </p>
                <h2>Users Under 18 Years of Age</h2>
                <p>We do not knowingly collect PII from users under the age of 18. If you are under the age of 18 DO NOT USE THE APP.
                </p>
                <h2>Other Websites and Services</h2>
                <p><span >We are not responsible for the practices employed by any websites, mobile applications or other services linked to or from our App, including the information or content contained within them. &nbsp;Please remember that when you use a link to go from our App to another website, mobile application or service, our Privacy Policy does not apply to those third-party websites, mobile application or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies. </span>
                </p>
                <h2>Data Breaches</h2>
                <p><span >If we learn of a data breach resulting in the disclosure of your PII, we will use good faith efforts to notify you of the same by email.</span>
                </p>
                <h2>Changes to Privacy Policy</h2>
                <p><span >The Company may modify or update this Privacy Policy from time to time, so please review it periodically. We may provide you additional forms of notice of modifications or updates as appropriate under the circumstances. Your continued use of the App or your non-cancellation of an Order (see the accompanying Terms of Use for our cancellation policy) after any modification to this Privacy Policy will constitute your acceptance of such modification.</span>
                </p>
                <h2>Questions</h2>
                <p><span >If you have any questions regarding this Privacy Policy, please feel free to email us at </span><a
                    href="mailto:ricardo@ctonexus.com"><span
                    >ricardo@ctonexus.com</span></a><span
                    >.</span></p>
            </div>
            </IonContent>
            </IonPage>
        );
    }
}
