"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageService = void 0;
const HttpService_1 = require("./HttpService");
class MessageService extends HttpService_1.HttpService {
    constructor() {
        super(...arguments);
        this.getUnreadMessagesCount = () => {
            return this.get(`/api/private-messages/unread-count`);
        };
        this.getPrivateConversation = (otherUserId) => {
            return this.get(`/api/private-messages?other_user_id=${otherUserId}`);
        };
        this.getPrivateConversationsPreview = () => {
            return this.get(`/api/private-messages/previews?v=2`);
        };
        this.getArticleMessages = (articleId) => {
            return this.get(`/api/articles/${articleId}/messages`);
        };
        this.sendArticleMessage = (articleId, message) => {
            return this.post(`/api/articles/${articleId}/messages`, {
                message
            });
        };
        this.sendPrivateMessage = (otherUserId, message) => {
            return this.post(`/api/private-messages`, {
                to_user_id: otherUserId,
                message
            });
        };
        this.makeReadMessages = (otherUserId) => {
            return this.post(`/api/read-messages`, {
                otherUserId: otherUserId,
            });
        };
        this.deleteArticleMessage = (articleId) => {
            if (HttpService_1.HttpService.isMock) {
                return Promise.resolve();
            }
            else {
                return this.delete(`/api/article-messages/${articleId}`);
            }
        };
        this.postGlobalMessage = (message) => {
            if (HttpService_1.HttpService.isMock) {
                return Promise.resolve();
            }
            else {
                return this.post(`/api/global-messages`, { message });
            }
        };
    }
}
exports.MessageService = MessageService;
