import "./Logo.scss";

import * as React from "react";
import {Link} from "react-router-dom";
import PinkLogo from "../../assets/Logo/logo-icon-only-pink.svg";
import WhiteLogo from "../../assets/Logo/logo-icon-only-white.svg";

interface Props {
    size?: 'small' | 'medium';
    color?: 'white' | 'pink';
}

export const Logo: React.FC<Props> = ({color, size}) => (
  <Link className="logo-component" to="/">
      {
          color === "pink" ?
            <img className={`image ${size || "medium"}`} src={PinkLogo} alt="Mojito"/>
            :
            <img className={`image ${size || "medium"}`}  src={WhiteLogo} alt="Mojito"/>
      }
      <div className={`name ${color} ${size || "medium"}`}>mojito</div>
  </Link>
);
