import { IonContent, IonPage } from '@ionic/react';
import * as React from 'react';
import {CSSProperties} from "react";
import { Helmet } from "react-helmet";

const globalStyle: CSSProperties = {
    padding: 20,
    listStyle: 'circle'
};

export default class CommunityGuidelines extends  React.Component {
    render() {
        return(
            <IonPage>
            <IonContent>
            <Helmet>
                <title>Mojito: Community Guidelines</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
                      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
                      crossOrigin="anonymous" />
            </Helmet>
            <div style={globalStyle}>
                <h1>Mojito | Community Guidelines</h1>
                <p>Welcome to Mojito! Being part of our global community means that you have a commitment from us to help ensure that you feel welcomed, safe, and free to be yourself.</p>
                <p>When you’re part of a community in which you’re free to be yourself, it also asks that you be responsible for your behavior and actions. When each member takes responsibility for their behavior, it creates an enjoyable and safe experience for all.</p>
                <p>Being successful on Mojito means that your behaviors are aligned with the four pillars of standard conduct:</p>
                <ol>
                    <li>Be Kind</li>
                    <li>Be Respectful</li>
                    <li>Be Safe</li>
                    <li>Be Real</li>
                </ol>
                <h3><span className="dark">BE KIND</span></h3>
                <p><strong>Champion kindness in all of your interactions. Treat others how you expect to be treated.</strong></p>
                <p><span className="large-line-height">HARASSMENT </span><br />
                    Don’t be rude. Do not bully, intimidate, defame, harass or stalk other members. Mojito has a zero tolerance policy for abuse. Reports of harassment, intimidation and assault are taken very seriously.</p>
                <p>If someone blocks you, do not attempt to circumvent the block feature or try to communicate with them. Just stop.</p>
                <p><span className="large-line-height">VIOLENCE AND PHYSICAL HARM </span><br />
                    You should never threaten to harm anyone through your words or physical actions. Inciting any form of physical or mental harm will not be tolerated. Inclusive of this, any content depicting violence, graphic or gory content is not allowed and will result in your account being moderated and may lead to your account being permanently banned.</p>
                <p><span className="large-line-height">DISCRIMINATORY BEHAVIOR &amp; HATE SPEECH </span><br />
                    You should be respectful in every interaction with other members. There is no place in our community for hate speech, racism, or bigotry towards any individual or group, and will result in your account being permanently banned.</p>
                <p><span className="large-line-height">BE KIND ON YOUR MOJITO PROFILE </span><br />
                    Your profile should not contain content that is violent, abusive, rude, or defamatory. If you’re looking for some tips on how to craft a great dating profile that reflects who you really are, you can find them here.</p>
                <p><span className="large-line-height">BE KIND IN THE MESSAGES YOU SEND </span><br />
                    Your messages to other members should always be kind. If your message is reported by another member, the offense will be investigated. Serious offenses will result in your account being banned.</p>
                <h3><span className="dark"> BE RESPECTFUL</span></h3>
                <p><strong>The foundation of all great relationships is respect. Lead with it.</strong></p>
                <p><span className="large-line-height">NUDITY/SEXUAL CONTENT</span><br />
                    Do not upload content that contains any nudity, sexually explicit or pornographic content to Mojito.</p>
                <p><span className="large-line-height">COPYRIGHT AND TRADEMARK INFRINGEMENT</span><br />
                    You should not post content that violates or infringes on anyone’s rights, rights of publicity, privacy, copyright, trademark or other intellectual property.</p>
                <p><span className="large-line-height">CUSTOMER CARE</span><br />
                    Our team is here to support you and help make your Mojito experience as enjoyable as possible. You should never abuse or threaten Customer Care Representatives under any circumstances.</p>
                <p><strong>Be respectful on your Mojito Profile.</strong></p>
                <p><span className="large-line-height">PROFILE IMAGES</span><br />
                    There are ground rules we expect you to observe when it comes to the types of images we allow on our platform.</p>
                <p>Images that contain the following content will result in your account being permanently banned:</p>
                <ul>
                    <li>Nudity or sexually explicit imagery</li>
                    <li>Shows of violence, violent gestures, or gruesome imagery</li>
                    <li>Depictions of criminal activity or drug use</li>
                    <li>Racist or hate imagery</li>
                </ul>
                <p>Images that contain the following are not allowed and will result in your account being moderated:</p>
                <ul>
                    <li>Shirtless photos out of context (shirtless on a beach is okay, but not in your bathroom)</li>
                    <li>Bikinis or swimwear out of context (outside is okay, but not inside)</li>
                    <li>Lingerie or underwear</li>
                    <li>Shirtless/underwear mirror selfies</li>
                </ul>
                <p>We have a zero tolerance policy where minors feature in photos. Please refrain from posting as we will remove them from your profile.</p>
                <p><span className="large-line-height">ATTACHING IMAGES TO MESSAGES</span><br />
                    We understand that part of getting to know another person is through sharing images that speak to your personal and unique experiences. With that understanding in mind, you may attach any image you like, so long as it does not contain:</p>
                <ul>
                    <li>Nudity or sexually explicit imagery</li>
                    <li>Shows of violence, violent gestures, or gruesome imagery</li>
                    <li>Depictions of criminal activity or drug use</li>
                    <li>Racist or hate imagery</li>
                </ul>
                <p><strong>Be respectful in the messages you send.</strong></p>
                <p>Do not send a message containing unwelcome sexual propositions or sexually explicit content.</p>
                <p>If you are married, or part of a couple looking for someone to join you, Mojito is not the place for you.</p>
                <p><span className="large-line-height">PROSTITUTION, ESCORTING, SOLICITATION, AND TRAFFICKING</span><br />
                    Promoting or advocating for commercial sexual services, human trafficking or non-consensual sexual acts on Mojito is not tolerated and will result in your account being permanently banned.</p>
                <p><span className="large-line-height">PRIVATE INFORMATION</span><br />
                    You should never solicit another member for their private information, which includes, but not limited to, information such as passwords, SSN / SIN, financial information, and home or work address. Any information regarding a member, public or private, shall not be used for business/commercial use or any other unlawful or nefarious purpose.</p>
                <p>To protect yourself and other members, you should never publicly or privately share private information that belongs to you or someone else.</p>
                <p><span className="large-line-height">ILLEGAL USAGE</span><br />
                    This should go without saying, but do not use Mojito to do anything illegal. Any profile or message content depicting criminal activity will be taken very seriously and will result in your account being permanently banned.</p>
                <h3><span className="dark">BE SAFE</span></h3>
                <p><strong>Use your best judgement and keep your personal safety top of mind. Always.</strong></p>
                <p><span className="large-line-height">MINORS</span><br />
                    You must be 18 years of age or older to use Mojito. If you are found to be underage, your account will be immediately removed and banned. In the event you encounter someone that has indicated they are under the age of 18, we ask that you please file a report to keep both them and our community safe.</p>
                <p>Never display sensitive personal information on your profile that could compromise the personal privacy and safety of yourself or someone else.</p>
                <p>When messaging other members on Mojito, don’t share more than you are comfortable with. When in doubt, keep your communication within the Mojito app. If you choose to share your personal information, stick to sharing basic contact information only.</p>
                <p>Reporting bad actors and users who do not adhere to our terms of service is straightforward and important, and can be done on the Mojito app by clicking &quot;Report User&quot; on the drop down menu when viewing the profile they wish to report. On the web, members can find the &quot;Report User&quot; button at the very bottom of the profile they wish to report.</p>
                <h3><span className="dark">BE REAL</span></h3>
                <p>When you're free to be yourself, the right people take notice. Let’s take a stand for genuineness.</p>
                <p><span className="large-line-height">SCAMMING</span><br />
                    Be human. Do not use Mojito to spam people, solicit money or business, or defraud people. Do not create accounts that impersonate another person or entity.</p>
                <p><span className="large-line-height">MULTIPLE ACCOUNTS</span><br />
                    Do not create multiple accounts on Mojito, unless permission has been granted by our Customer Support Service staff. Inclusive of this, do not attempt to use an account that isn’t yours.</p>
                <p><span className="large-line-height">BE REAL ON YOUR MOJITO PROFILE</span><br />
                    All of the information in your profile should be truthful and about you only. Never attempt to mask characters, content, or game the system to get around our rules for content.</p>
                <p><span className="large-line-height">PROFILE IMAGES</span><br />
                    All your profile images should be focused on you, with your face clearly visible.</p>
                <p>Some exceptions are:</p>
                <ul>
                    <li>Sunglasses and hats are allowed as long as your entire face is visible in the image</li>
                    <li>Pets and children are acceptable as long as your face is clearly visible in the image</li>
                </ul>
                <p><strong>Group shots:</strong></p>
                <ul>
                    <li>Images that contain multiple people are confusing to other members as they cannot tell who you are. For that reason, we may remove them</li>
                </ul>
                <p><strong>Filters:</strong></p>
                <ul>
                    <li>We ask all members to represent themselves truthfully through the use of clear unedited images. For this reason, images that are heavily edited or use filters (e.g. Snapchat) will be removed</li>
                </ul>
                <p><strong>Memes, Cartoons, and Text:</strong></p>
                <ul>
                    <li>Memes certainly have a time and a place, but it’s not here. Images that contain overlaid text or watermarks are not allowed either and will be removed</li>
                </ul>
                <p><span className="large-line-height">BE REAL IN THE MESSAGES YOU SEND</span></p>
                <p>Be mindful in your first messages to someone. In real life you wouldn’t introduce yourself with your phone number, email address, Instagram handle, Snapchat or kik, so don’t do that here either.</p>
                <p>Mojito is not the place to promote external links in your messages or on your profile. Same goes for solicitation or commercial promotion of any kind.</p>
                <h3>BEFORE YOU GO</h3>
                <p><span className="large-line-height">ACCOUNT TERMINATION</span><br />
                    Mojito reserves the right to investigate and, if necessary, terminate your account should one or more of the Community Guidelines have been proven to be violated.</p>
                <p>Mojito also reserves the right to terminate accounts, without refund, if these Community Guidelines were abused or our service was misused in any way.</p>
                <p><span className="large-line-height">OFFLINE BEHAVIOR / REAL LIFE</span><br />
                    Being Kind, Safe, Respectful, and Real applies to not only your online experience but your offline experience as well. With that said, our Community Guidelines apply, and are enforced, both on Mojito and in real life. If you’ve experienced bad behavior with another member in real life, we take that seriously and want to hear about it. Please contact us through our Help Center.</p>
            </div>
            </IonContent>
            </IonPage>
        );
    }
}
