import "./Toast.scss";

import * as React from "react";
import {IonToast} from "@ionic/react";

export interface ToastMessage {
    message: string;
    type: "danger" | "success",
    dismiss: () => void;
}

interface Props {
    toastMessage: ToastMessage | null;
}

export const Toast: React.FC<Props> = ({toastMessage}: Props) => {

    return (
      <IonToast
        cssClass="toast-component"
        isOpen={!!toastMessage}
        onDidDismiss={toastMessage ? toastMessage.dismiss : () => {}}
        message={toastMessage ? toastMessage.message : ""}
        color={toastMessage ? toastMessage.type : ""}
        position="top"
        duration={5000}
      />
    )
}
