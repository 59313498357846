import '../../theme/LoggedOutCommon.scss';
import * as React from 'react';
import {ApplicationContext} from "../../ApplicationContext";
import {LogoV2} from "../../components/Common/LogoV2";
import {AuthService} from "mojito-shared";
import {IonButton, IonInput} from "@ionic/react";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import * as _ from 'lodash';
import {useHistory} from "react-router-dom";

export const PasswordResetPage: FC = () =>  {

    const [email, setEmail] = useState<string | null>(null);
    const [secret, setSecret] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [repeatPassword, setRepeatPassword] = useState<string | null>(null);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [firstInput, setFirstInput] = useState<boolean>(true);
    const [inlineError, setInlineError] = useState<string | null>(null);

    const {showError, showSuccess} = useContext(ApplicationContext);
    const history = useHistory();

    const onSubmit = async () => {
        if(!email || !secret || !password || password != repeatPassword) {
            showError("Internal application error. Please contact support.");
            return;
        }
        try {
            setLoading(true);
            await AuthService.resetPassword(email, secret, password)
            showSuccess('Password updated successfully. Please login again.');
            setPassword(null);
            setRepeatPassword(null);
        } catch (error: any) {
            showError(error.message)
        }
        setLoading(false);
    };

    const checkPassword = (newP: string | null, repeatP: string | null) => {
        setFirstInput(false);
        if(newP && repeatP) {
            if(newP !== repeatP) {
                setInlineError("Passwords must match.");
            } else {
                setInlineError(null)
            }
        } else {
            setInlineError(null)
        }
    }

    const debouncedCheckPassword = useCallback(_.debounce(checkPassword, 1000, {leading: false, trailing: true}), []);

    useEffect(() => {
        if(password !== null && repeatPassword !== null) {
            if(firstInput) {
                debouncedCheckPassword(password, repeatPassword);
            } else {
                checkPassword(password, repeatPassword);
            }
        }
    }, [password, repeatPassword]);

    useEffect(() => {
        let params = new URLSearchParams(history.location.search);
        const secret = params.get('secret');
        const email = params.get('email');
        if(!secret || !email) {
            setInlineError("Invalid reset url.");
        } else {
            setEmail(email);
            setSecret(secret);
        }
    }, []);

    return(
        <div className="register-login-page-component">

            <div className="header">
                <LogoV2 size='medium' color='white'/>
            </div>
            <div className="form-container reset-password">
                <div className="form-card-wrapper">
                    <div className="register-login-form-card">

                        <h5 className="form-card-header">
                            Reset Password Form
                        </h5>

                        <IonInput
                            type="password"
                            required
                            className="text-field"
                            placeholder="Password"
                            name="password"
                            value={password}
                            disabled={!email || !secret}
                            onInput={(event) => setPassword(event.currentTarget.value as string)}
                        />

                        <IonInput
                            required
                            className="text-field"
                            type='password'
                            placeholder="Confirm Password"
                            name="repeat_password"
                            value={repeatPassword}
                            disabled={!email || !secret}
                            onInput={(event) => setRepeatPassword(event.currentTarget.value as string)}
                        />

                        {
                            inlineError &&
                            <div className="error-text">
                                { inlineError }
                            </div>
                        }

                        <div className="submit-button-container">
                            <IonButton
                                className="submit-button"
                                type="submit"
                                color="dark"
                                onClick={onSubmit}
                                disabled={password !=repeatPassword || loading || !email || !secret}>
                                {
                                    loading ? "Please Wait" : "Reset"
                                }
                            </IonButton>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );


}
