import "./MojitoButton.scss";

import * as React from "react";
import {Link} from "react-router-dom";
import {IonIcon} from "@ionic/react";
import {chevronForward} from "ionicons/icons";

interface Props {
    className?: string;
    url: string;
    label: string;
    icon?: any;
    variant?: 'link' | 'button';
    color?: "pink";
}

export const MojitoButton: React.FC<Props> = (props: Props) => {
    return (
      <Link  className={`mojito-button-component  ${props.variant || 'button'}  ${props.className}`}  to={props.url}>
          <div className={`label ${props.color}`}>{props.label}</div>
          <div className={`icon ${props.color}`}>
              {props.icon ? props.icon : <IonIcon icon={chevronForward} />}
          </div>
      </Link>
    )
}
