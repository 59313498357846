import * as React from "react";
import {ApiUserLike, CurrentUser, Nullable, Profile, Token} from "mojito-shared";

export interface ApplicationContextType {
    currentUser: Nullable<CurrentUser>;
    onLogin: (token: Token) => Promise<void>;
    handleLogout: () => void;
    globalErrorHandler: (error: any) => void;
    updateUserProfile: (profileData: Partial<Profile>) => Promise<number>;
    updateUserLikes: (likes?: ApiUserLike[]) => Promise<void>,
    refreshUserData: () => Promise<void>;
    showError: (message: string) => void;
    showSuccess: (message: string) => void;
    closeNotice:any;
    isProfileCompleted: boolean;
}

export const ApplicationContext: React.Context<ApplicationContextType> =
    React.createContext<ApplicationContextType>({
        currentUser: null,
        onLogin: () => Promise.reject('Application Error - Not Implemented'),
        handleLogout: () => {},
        showSuccess: () => {},
        showError: () => {},
        globalErrorHandler: () => {},
        updateUserProfile: () => Promise.reject('Application Error - Not Implemented'),
        refreshUserData: () => Promise.reject('Application Error - Not Implemented'),
        updateUserLikes: () => Promise.reject('Application Error - Not Implemented'),
        closeNotice:()=>{},
        isProfileCompleted: true
    });
