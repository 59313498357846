import "./LoginRegisterPage.scss";

import * as React from "react";
import {useContext, useEffect, useState} from "react";

import {IonButton, IonContent, IonInput, IonItem, IonPage, IonSpinner, IonToast} from "@ionic/react";
import {AuthService} from "mojito-shared";
import {IonicStorageAdapter} from "../../shared/IonicStorageAdapter";
import {ApplicationContext} from "../../ApplicationContext";
import {trackPageView, trackRegistrationConversion} from "../../shared/Utilities";
import {Logo} from "../../components/Logo/Logo";
import {MojitoButton} from "../../components/MojitoButton/MojitoButton";

export const LoginPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const {onLogin} = useContext(ApplicationContext);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');


    useEffect(() => {
        trackPageView("login");
    }, []);

    useEffect(() => {
        if(email && password) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [email, password]);


    const submitRegistration = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const authToken = await AuthService.login(email, password);
            await onLogin(authToken);
        } catch (e) {
            if((e as any).message) {
                setError((e as any).message);
            } else {
                setError("Internal Server Error.");
            }
            setIsLoading(false);
        }
    };


    return (
      <IonPage>
          <IonContent>
              <div className="login-register-page">

                  <div className="header">
                      <Logo size='medium' color='white'/>
                  </div>

                  <div className="form-container">
                      <div className="register-login-form-card">
                          <form onSubmit={submitRegistration}>

                              <div className="form-card-header login">
                                  Login
                              </div>

                              <IonItem className="input-item">
                                  <IonInput
                                    required={true}
                                    placeholder="Email"
                                    className="text-field"
                                    onIonChange={(event) => setEmail(event.detail.value || '')}
                                    value={email} />
                              </IonItem>
                              <IonItem className="input-item">
                                  <IonInput
                                    required={true}
                                    placeholder="Password"
                                    type="password"
                                    className="text-field"
                                    onIonChange={(event) => setPassword(event.detail.value || '')}
                                    value={password} />
                              </IonItem>

                              <div className='register-caption'>
                                  We will never share your personal information with anyone. You
                                  may request your account and associated data to be deleted at any
                                  time.
                              </div>
                              <div className="submit-button-container">
                                  <IonButton
                                    type="submit"
                                    color="dark"
                                    className="submit-button"
                                    disabled={isDisabled || isLoading}>
                                      {
                                          isLoading ?
                                            <IonSpinner name="crescent" /> : "Login"
                                      }
                                  </IonButton>
                              </div>
                          </form>
                      </div>
                      <div className="link-container">
                          <MojitoButton
                            className="header-login-button-container"
                            url="/register" label="Register Instead" variant="link"
                          />
                      </div>
                  </div>
              </div>

          </IonContent>
      </IonPage>
    )
}
