/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import '../../theme/variables.css';
import '../../theme/global.scss';
import '../../theme/fonts.scss';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

/* Libraries */
import {
    IonApp,
    setupIonicReact
} from '@ionic/react';
import {API_ROOT} from "../../shared/Constants";
import * as React from "react";
import {
    ApiUserLike,
    AuthService,
    CurrentUser,
    HttpService,
    Profile,
    ProfileService,
    Token
} from "mojito-shared";
import {IonicStorageAdapter} from "../../shared/IonicStorageAdapter";
import {Navigation} from "../Navigation/Navigation";
import { ApplicationContext, ApplicationContextType } from '../../ApplicationContext';
import {useState} from "react";
import * as Mixpanel from "mixpanel-browser";
import {getProfileIsCompleted} from "../../shared/Utilities";
import 'animate.css';
import {Toast, ToastMessage} from "../Toast/Toast";

setupIonicReact();

HttpService.init({
    storage: IonicStorageAdapter,
    apiRoot: API_ROOT
});


const App: React.FC = () => {

    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
    const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(true);
    const [toastMessage, setToastMessage] = useState<ToastMessage | null>(null);


    const globalErrorHandler = () => {};
    const closeNotice = () => {};
    const updateUserLikes = async (likes?: ApiUserLike[]) => {};

    const showError = (message: string) => {
        setToastMessage({
            type: "danger",
            message: message,
            dismiss: () => setToastMessage(null)
        })
    };
    const showSuccess = (message: string) => {
        setToastMessage({
            type: "success",
            message: message,
            dismiss: () => setToastMessage(null)
        })
    };

    const updateUserProfile = (profileData?: Partial<Profile>): Promise<number> => {
        if (currentUser && profileData) {
            return ProfileService.updateProfile(profileData)
              .then((user: CurrentUser) => {
                  setCurrentUser(user);
                  return user.grant || 0;
              })
        } else {
            return Promise.reject();
        }
    };

    const refreshUserData = async () => {
        const user = await AuthService.me();
        const status: string | null = getProfileIsCompleted(user);
        setCurrentUser(user);
        setIsProfileCompleted(status === "profileCompleted");
        //await updateUnreadMessagesCount();
        await updateUserLikes();
    };

    const onLogin = async (token: Token): Promise<any> => {
        await AuthService.setAuthCredentials(token);
        const user = await AuthService.me();
        Mixpanel.track(`${user.id}`);
        setCurrentUser(user);
        await updateUserLikes();
    };

    const handleLogout = () => {
        AuthService.logout();
        setCurrentUser(null);
    };

    const getContext = (): ApplicationContextType => ({
        currentUser: currentUser,
        onLogin: onLogin,
        handleLogout: handleLogout,
        globalErrorHandler: globalErrorHandler,
        updateUserProfile: updateUserProfile,
        refreshUserData: refreshUserData,
        showError: showError,
        showSuccess: showSuccess,
        closeNotice: closeNotice,
        isProfileCompleted: isProfileCompleted,
        updateUserLikes: updateUserLikes
    });

    // useEffect(() => {
    //     AuthService.me().then(user => {
    //         setCurrentUser(user);
    //         updateUserLikes();
    //         setAppLoading(false);
    //     }).catch((error: HttpError) => {
    //         setAppLoading(false);
    //         if (error.statusCode === 401) {
    //             handleLogout();
    //         }
    //     });
    // }, []);

    return(
      <IonApp>
          <ApplicationContext.Provider value={getContext()}>
              <Navigation/>
              <Toast toastMessage={toastMessage} />
          </ApplicationContext.Provider>
      </IonApp>
    );

};

export default App;
