import '../../theme/LoggedOutCommon.scss';

import * as React from 'react';
import {ApplicationContext} from "../../ApplicationContext";
import {LogoV2} from "../../components/Common/LogoV2";
import {AuthService} from "mojito-shared";
import {IonButton, IonInput} from "@ionic/react";
import {FC, useContext, useState} from "react";



export const ForgotPasswordPage: FC = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string | null>(null);

    const {showSuccess, showError} = useContext(ApplicationContext);

    const onSubmitEmail = async () => {
        setLoading(true);
        try {
            await AuthService.initializeResetPassword(email ?? "");
            showSuccess("Please check your email for password reset instructions");
            setEmail("");
        } catch (error) {
            showError("We were unable to reset your password. Make sure you entered a valid email.")
        }
        setLoading(false);
    };

    return(
        <div className="register-login-page-component forgot-password">

            <div className="header">
                <LogoV2 size='medium' color='white'/>
            </div>

            <div className="form-container forgot-password">
                <div className="form-card-wrapper">
                    <div className="register-login-form-card">

                        <h5 className="form-card-header">
                            Forgot Password Form
                        </h5>

                        <div
                            className="sub-text">
                            Enter your email address to reset your password.
                        </div>

                        <IonInput
                            type="text"
                            className="text-field"
                            value={email}
                            onInput={(event) => setEmail(event.currentTarget.value as string)} />

                        <div className="submit-button-container">
                            <IonButton
                                className="submit-button"
                                color="dark"
                                type={"submit"}
                                shape="round"
                                disabled={!email || loading}
                                onClick={onSubmitEmail}>
                                {
                                    loading ? "Please Wait" :  "Submit"
                                }
                            </IonButton>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );


}
