import {Nullable, CurrentUser, ApiUser, ApiPostWrapper, ApiPostTypes} from "mojito-shared";
import moment from "moment";
import * as Mixpanel from "mixpanel-browser";
import * as mixpanel from "mixpanel-browser";


export const truncateText = (originalString: string, max?: number) => {
    const maxLength = max || 160;
    if (originalString.length >= maxLength) {
        let truncatedText = originalString.substr(0, maxLength)
        let getLastSpaceIndex = truncatedText.lastIndexOf(" ")
        if (getLastSpaceIndex === maxLength) {
            return truncatedText + "..."
        }
        else {
            return truncatedText.substr(0, getLastSpaceIndex) + "..."
        }
    }
    else {
        return originalString
    }

}


export const getBase64 = (imageUrl: string) => {
    const base64Image = new Promise(resolve => {
        let baseImage = new Image();
        baseImage.setAttribute('crossOrigin', 'anonymous');
        baseImage.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = baseImage.naturalWidth;
            canvas.height = baseImage.naturalHeight;
            var ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.drawImage(baseImage, 0, 0);
            }
            var encodedImage = canvas.toDataURL("image/jpeg");
            resolve(encodedImage)
        }
        baseImage.src = imageUrl
    })
    base64Image.then((result) => {
        return result;
    })
    return base64Image

}


export const getProfileIsCompleted = (user: Nullable<ApiUser | CurrentUser>) => {
    let profileStatus;

    if (user !== null) {

        if (user.pictures.length >= 3 && user.first_name && user.city && user.dob &&
          user.education && user.zipcode) {
            profileStatus = "profileCompleted";
            return profileStatus;
        }
        else if (user.pictures.length < 3) {
            profileStatus = "profilePicInComplete";
            return profileStatus;
        }
        else {
            profileStatus = "profileIncomplete";
            return profileStatus;
        }
    } else {
        return null;
    }

}


export function dragElement(targetId: string) {
    var pos1 = 0, pos2 = 0, maxDelta = 0;
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
        targetElement.addEventListener('mousedown', dragMouseDown, {passive: false});
        targetElement.addEventListener('touchstart', dragMouseDown, {passive: false});
        const parentElement = targetElement.parentElement;
        if (parentElement) {
            if (parentElement.clientWidth < targetElement.clientWidth) {
                maxDelta = targetElement.clientWidth - parentElement.clientWidth;
            }
        }
    }

    function dragMouseDown(e: any) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos2 = getClientX(e);
        document.addEventListener('mouseup', closeDragElement, {passive: false});
        document.addEventListener('touchend', closeDragElement, {passive: false});
        // call a function whenever the cursor moves:
        document.addEventListener('mousemove', elementDrag, {passive: false});
        document.addEventListener('touchmove', elementDrag, {passive: false});
    }

    function getClientX(e: any) {
        if (e.clientX) {
            return e.clientX;
        } else if (e.targetTouches && e.targetTouches.length) {
            return e.targetTouches[0].clientX;
        }
    }

    function elementDrag(e: any) {
        if (!targetElement) {
            return;
        }
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos2 - getClientX(e);
        pos2 = getClientX(e);


        // set the element's new position:
        const newPosition = targetElement.offsetLeft - pos1;
        if (Math.abs(newPosition) < maxDelta && newPosition < 0) {
            targetElement.style.left = newPosition + "px";
        }

    }

    function closeDragElement() {
        // stop moving when mouse button is released:
        document.removeEventListener('mouseup', closeDragElement);
        document.removeEventListener('touchend', closeDragElement);
        document.removeEventListener('mousemove', elementDrag);
        document.removeEventListener('touchmove', elementDrag);
    }
}

// export const sortIcebreakers = (a: ApiIcebreaker, b: ApiIcebreaker) => {
//     return b.content.publicationDate.toString().localeCompare(a.content.publicationDate.toString());
// }

export const wrapApiPost = (post: any, type: ApiPostTypes): ApiPostWrapper => {
    return { type, post  };
};

export const trackPageView = (pageName: string, props: any = {} ) => {
    Mixpanel.track('page_view', {page: pageName, ...props});
    // @ts-ignore
    window.gtag('event', 'page_view_custom', {
        'label': pageName,
        'send_to': 'analytics'
    });
}

export const trackEvent = (name: string, props: any = {}) => {
    Mixpanel.track(name, props);

    // @ts-ignore
    window.gtag('event', name, {
        'send_to': 'analytics'
    });

    // @ts-ignore
    fbq('trackCustom', name, props);
}

export const trackPageViewWithDuration = (pageName: string, duration: number, props: any = {} ) => {
    Mixpanel.track('page_view_with_duration', {page: pageName, duration, ...props});
    // @ts-ignore
    window.gtag('event', 'page_view_custom_with_duration', {
        'label': pageName,
        'value': duration,
        'send_to': 'analytics'
    });
}

export const trackRegistrationConversion = (utmSource: string | null) => {
    if (utmSource === "google_ads") {
        // @ts-ignore
        window.gtag('event', 'conversion', {
            'send_to': process.env.REACT_APP_GOOGLE_CONVERSION_TAG
        });
    }
    Mixpanel.track('registration_submitted');

    // @ts-ignore
    window.gtag('event', 'conversion', {
        'send_to': 'analytics',
        'label': 'registration_submitted',
        'value': 1
    });

    // @ts-ignore
    window.gtag('event', 'registration_submitted', {
        'send_to': 'analytics'
    });
}

