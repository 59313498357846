import * as React from 'react';
import {css, StyleSheet} from "aphrodite/no-important";
import {Link} from "react-router-dom";

interface Props {
  size?: 'small' | 'medium';
  color?: 'black' | 'white';
  clickable?: boolean;
}
export const LogoV2: React.FC<Props> = (props) => {

  const imageSize = props.size === 'small' ? '25px' : '50px';
  const headerSize = props.size === 'small' ? '16px' : '34px';
  const headerPaddingTop = props.size === 'small' ? '2px' : 0;

  const styles = StyleSheet.create({
    logo: {
      width: imageSize,
    },
    header: {
      display: 'inline-block',
      marginLeft: '20px',
      fontFamily: 'Google Sans',
      paddingTop: headerPaddingTop,
      fontSize: headerSize,
      fontWeight: 'bold',
    },
    link: {
      color: props.color === 'white' ? '#fff' : '#666',
      display: 'inline-flex',
      alignItems: 'center',
    }
  });

  const wrapper = (contents: React.ReactElement) => {
    if(props.clickable || props.clickable === undefined) {
      return(
          <Link className={css(styles.link)}  to="/">
            {contents}
          </Link>
      );
    } else {
      return contents;
    }
  };

  return(
      wrapper(
          <>
            <img className={css(styles.logo)} src={require('../../assets/images/logo.png')} alt="Mojito"/>
            <div className={css(styles.header)}>mojito</div>
          </>
      )
  );
};
