import "./HomePageBanner.scss";

import * as React from "react";
import {AppStoreButton} from "../Common/AppStoreButton";

const PINK_LOGO_URL = "https://mojito-dating.imgix.net/assets/website/logo-pink.png?w=400";


export const HomePageBanner: React.FC = () => {


    return (
      <div className="home-page-banner-component">
          <div className="wrapper">

              <div className="banner-content">

                  <img className="logo-png" src={PINK_LOGO_URL} />

                  <div className="title-small">The Latin</div>
                  <div className="title-big">Flavored</div>
                  <div className="title-small">Dating App</div>
                  <p>
                      Come for the dancing, stay for the people.
                  </p>
                  <div className="banner-button">
                      <AppStoreButton />
                  </div>
              </div>
          </div>
      </div>
    )
}
