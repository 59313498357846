"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REACT_APP_GOOGLE_ANALYTICS_ID = exports.REACT_APP_GOOGLE_ADWORDS_ID = exports.REACT_APP_MIXPANEL_TOKEN = exports.API_ROOT = exports.IS_BROWSER = exports.IS_APP = void 0;
if (!["nativeApp", "webBrowser"].includes(process.env.REACT_APP_CLIENT || "")) {
    throw new Error("Invalid value for environment variable REACT_APP_CLIENT");
}
exports.IS_APP = process.env.REACT_APP_CLIENT === "nativeApp";
exports.IS_BROWSER = process.env.REACT_APP_CLIENT === "webBrowser";
if (!process.env.REACT_APP_API_ROOT) {
    throw new Error("Environment variable REACT_APP_API_ROOT not set.");
}
exports.API_ROOT = process.env.REACT_APP_API_ROOT || '';
if (!process.env.REACT_APP_MIXPANEL_TOKEN) {
    throw new Error("Environment variable REACT_APP_MIXPANEL_TOKEN not set.");
}
exports.REACT_APP_MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
if (!process.env.REACT_APP_GOOGLE_ADWORDS_ID) {
    throw new Error("Environment variable REACT_APP_GOOGLE_ADWORDS_ID not set.");
}
exports.REACT_APP_GOOGLE_ADWORDS_ID = process.env.REACT_APP_GOOGLE_ADWORDS_ID;
if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    throw new Error("Environment variable REACT_APP_GOOGLE_ANALYTICS_ID not set.");
}
exports.REACT_APP_GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
