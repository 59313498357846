import * as React from 'react';
import {CSSProperties} from "react";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom";
import {IonContent, IonPage} from "@ionic/react";

const globalStyle: CSSProperties = {
    padding: 20,
    listStyle: 'circle'
};

export default class TermsOfUse extends  React.Component {
    render() {
        return(
            <IonPage>
            <IonContent>
            <Helmet>
                <title>Mojito: Terms of Use Agreement</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
                      integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
                      crossOrigin="anonymous" />
            </Helmet>

            <div style={globalStyle}>

                <h1>Mojito |  Terms of Use Agreement</h1>

                <p className="center">Effective on April 1, 2020.</p>
                <p>California subscribers: You may cancel your subscription, without penalty or obligation, at any time
                    prior to midnight of the third business day following the date you subscribed. If you subscribed
                    using an External Service (e.g., Apple ID, Google Play), you must cancel through your External
                    Service, as set forth in more detail in Section 8a. If you subscribed through your Apple ID, refunds
                    are handled by Apple/Google, not Mojito. You can request a refund from Apple through your Apple ID
                    account on your phone or at <a href="https://getsupport.apple.com">https://getsupport.apple.com</a>.
                    All other users may request a refund by contacting Mojito Customer Service at
                    <a href='mailto:ricardo@ctonexus.com'> ricardo@ctonexus.com</a>, In accordance with Cal. Civ. Code §1789.3,
                    you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of
                    the California Department of Consumer Affairs by contacting them in writing at Consumer Information
                    Division, 1625 North Market Blvd., Suite N112 Sacramento, CA 95834, or by telephone at (800) 952-5210.</p>
                <p><strong><em>We have included brief summaries at the beginning of each section to make it easier for
                    you to read and understand this agreement. The summaries do not replace the text of each section,
                    and you should still read each section in its entirety.</em></strong></p>
                <h3>1. INTRODUCTION</h3>
                <p><strong><em>By accessing or using Mojito's services, you agree to be bound by these terms, including our
                    Privacy Policy, so it is important that you read this agreement carefully before you
                    create an account. We may update the terms from time to time, so you should check this page
                    regularly for updates.</em></strong></p>
                <p>Welcome to Mojito, operated by CTO Nexus LLC. As used in this Agreement, the
                    terms &quot;Mojito,&quot; &quot;us,&quot; &quot;we,&quot; the &quot;Company&quot;,
                    and &quot;our&quot; shall refer to CTO Nexus LLC.</p>
                <p>By accessing or using our Services on mojitodating.com (the &quot;Website&quot;), the Mojito mobile application
                    (the &quot;App&quot;), or any other platforms or services Mojito may offer (collectively,
                    the &quot;Service&quot; or our &quot;Services&quot;), you agree to, and are bound by, these Terms of
                    Use (the &quot;Terms&quot; or &quot;Agreement&quot;). This Agreement applies to anyone who accesses
                    or uses our Services, regardless of registration or subscription status.</p>
                <p>Your use of our Services is also subject to the Privacy Policy, Community Guidelines,
                    and any terms disclosed and agreed to by you when you purchase additional features, products, or
                    services from Mojito (&quot;Additional Terms Upon Purchase&quot;), which are incorporated into this
                    Agreement by reference. If you do not wish to be bound by this Agreement, do not use our
                    Services.</p>
                <p>We reserve the right to modify, amend, or change the Terms at any time. Notice of any material change
                    will be posted on this page with an updated effective date. In certain circumstances, we may notify
                    you of a change to the Terms via email or other means, as appropriate under the circumstances;
                    however, you are responsible for regularly checking this page for notice of any changes. We agree
                    that future changes will not be retroactive without your consent. Your continued use of our Services
                    constitutes your acceptance of any change, and you will be legally bound by the updated Terms. If
                    you do not accept a change to the terms, you should stop using our Services immediately.</p>
                <h3>2. ACCOUNT ELIGIBILITY; YOUR RESPONSIBILITIES</h3>
                <p><strong><em>Before you create an account on Mojito, make sure you are eligible to use our Services. This
                    Section also details what you can and can't do when using the Services, as well as the rights you
                    grant Mojito.</em></strong></p>
                <p><strong>By using our Services, you represent and warrant that:</strong></p>
                <ol>
                    <li>You are at least 18 years old;</li>
                    <li>You are legally qualified to enter a binding contract with Mojito;</li>
                    <li>You are not located in a country that is subject to a U.S. Government embargo, or that has been
                        designated by the U.S. Government as a &quot;terrorist supporting&quot; country;
                    </li>
                    <li>You are not on any list of individuals prohibited from conducting business with the United
                        States;
                    </li>
                    <li>You are not prohibited by law from using our services;</li>
                    <li>You have not have been convicted of or pled no contest to a felony or indictable offense (or
                        crime of similar severity), a sex crime, or any crime involving violence;
                    </li>
                    <li>You are not required to register as a sex offender with any state, federal or local sex offender
                        registry;
                    </li>
                    <li>You do not have more than one account on our Services; and</li>
                    <li>You have not previously been removed from our Services by us, unless you have our express
                        written permission to create a new account.
                    </li>
                </ol>
                <p>If at any time you cease to meet these requirements, you must immediately delete your account.</p>
                <p><strong>You agree to:</strong></p>
                <ol>
                    <li>Comply with these Terms, and check this page from time to time to ensure you are aware of any
                        change;
                    </li>
                    <li>Comply with all applicable laws, including without limitation, privacy laws, intellectual
                        property laws, anti-spam laws, and regulatory requirements;
                    </li>
                    <li>Use the latest version of the Website and/or App;</li>
                    <li>Treat other users in a courteous and respectful manner, both on and off our Services;</li>
                    <li>Be respectful when communicating with any of our customer care representatives or other
                        employees;
                    </li>
                    <li>Review the Safety Tips;</li>
                    <li>Review and comply with the Community Guidelines, as updated from time to time; and</li>
                    <li>Maintain a strong password and take reasonable measures to protect the security of your login
                        information.
                    </li>
                </ol>
                <p><strong>You agree that you will not:</strong></p>
                <ol>
                    <li>Misrepresent your identity, age, current or previous positions, qualifications, or affiliations
                        with a person or entity;
                    </li>
                    <li>Use the Services in a way that damages the Services or prevents their use by other users;</li>
                    <li>Use our Services in a way to interfere with, disrupt or negatively affect the platform, the
                        servers, or our Services' networks;
                    </li>
                    <li>Use our Services for any harmful, illegal, or nefarious purpose;</li>
                    <li>Harass, bully, stalk, intimidate, assault, defame, harm or otherwise mistreat any person;</li>
                    <li>Post or share Prohibited Content (see below);</li>
                    <li>Solicit passwords for any purpose, or personal identifying information for commercial or
                        unlawful purposes from other users or disseminate another person's personal information without
                        his or her permission;
                    </li>
                    <li>Solicit money or other items of value from another user, whether as a gift, loan, or form of
                        compensation;
                    </li>
                    <li>Use another user's account;</li>
                    <li>Use our Services in relation to fraud, a pyramid scheme, or other similar practice; or</li>
                    <li>Violate the terms of the license granted to you by Mojito (see Section 6 below).</li>
                    <li>Disclose private or proprietary information that you do not have the right to disclose;</li>
                    <li>Copy, modify, transmit, distribute, or create any derivative works from, any Member Content or
                        Our Content, or any copyrighted material, images, trademarks, trade names, service marks, or
                        other intellectual property, content or proprietary information accessible through our Services
                        without Mojito's prior written consent;
                    </li>
                    <li>Express or imply that any statements you make are endorsed by Mojito;</li>
                    <li>Use any robot, crawler, site search/retrieval application, proxy or other manual or automatic
                        device, method or process to access, retrieve, index, &quot;data mine,&quot; or in any way
                        reproduce or circumvent the navigational structure or presentation of our Services or its
                        contents;
                    </li>
                    <li>Upload viruses or other malicious code or otherwise compromise the security of our Services;
                    </li>
                    <li>Forge headers or otherwise manipulate identifiers to disguise the origin of any information
                        transmitted to or through our Services;
                    </li>
                    <li>&quot;Frame&quot; or &quot;mirror&quot; any part of our Services without Mojito's prior written
                        authorization;
                    </li>
                    <li>Use meta tags or code or other devices containing any reference to Mojito or the platform (or any
                        trademark, trade name, service mark, logo or slogan of Mojito) to direct any person to any other
                        website for any purpose;
                    </li>
                    <li>Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise
                        disassemble any portion of our Services, or cause others to do so;
                    </li>
                    <li>Use or develop any third-party applications that interact with our Services or Member Content or
                        information without our written consent;
                    </li>
                    <li>Use, access, or publish the Mojito application programming interface without our written consent;
                    </li>
                    <li>Probe, scan or test the vulnerability of our Services or any system or network; or</li>
                    <li>Encourage, promote, or agree to engage in any activity that violates these Terms.</li>
                </ol>
                <p><strong>Prohibited Content - Mojito prohibits uploading or sharing content that:</strong></p>
                <ol>
                    <li>Is likely to be deemed offensive or to harass, upset, embarrass, alarm or annoy any other
                        person;
                    </li>
                    <li>Is obscene, pornographic, violent or otherwise may offend human dignity, or contains nudity;
                    </li>
                    <li>Is abusive, insulting or threatening, discriminatory or that promotes or encourages racism,
                        sexism, hatred or bigotry;
                    </li>
                    <li>Encourages or facilitates any illegal activity including, without limitation, terrorism,
                        inciting racial hatred or the submission of which in itself constitutes committing a criminal
                        offense;
                    </li>
                    <li>Is defamatory, libelous, or untrue;</li>
                    <li>Relates to commercial activities (including, without limitation, sales, competitions,
                        promotions, and advertising, solicitation for services, &quot;sugar daddy&quot; or &quot;sugar
                        baby&quot; relationships, links to other websites or premium line telephone numbers);
                    </li>
                    <li>Involves the transmission of &quot;junk&quot; mail or &quot;spam&quot;;</li>
                    <li>Contains any spyware, adware, viruses, corrupt files, worm programs or other malicious code
                        designed to interrupt, damage or limit the functionality of or disrupt any software, hardware,
                        telecommunications, networks, servers or other equipment, Trojan horse or any other material
                        designed to damage, interfere with, wrongly intercept or expropriate any data or personal
                        information whether from Mojito or otherwise;
                    </li>
                    <li>Infringes upon any third party's rights (including, without limitation, intellectual property
                        rights and privacy rights);
                    </li>
                    <li>Was not written by you or was automatically generated, unless expressly authorized by Mojito;</li>
                    <li>Includes the image or likeness of another person without that person's consent (or in the case
                        of a minor, the minor's parent or guardian), or is an image or likeness or a minor unaccompanied
                        by the minor's parent or guardian;
                    </li>
                    <li>Is inconsistent with the intended use of the Services; or</li>
                    <li>May harm the reputation of Mojito or its affiliates.</li>
                </ol>
                <p>The uploading or sharing of content that violates these terms (&quot;Prohibited Content&quot;) may
                    result in the immediate suspension or termination of your account.</p>
                <h3>3. CONTENT</h3>
                <p><strong><em>It is important that you understand your rights and responsibilities with regard to the
                    content on our Services, including any content you provide or post. You are expressly prohibited
                    from posting inappropriate content.</em></strong></p>
                <p>While using our Services, you will have access to: (i) content that you upload or provide while using
                    our Services (&quot;Your Content&quot;); (ii) content that other users upload or provide while using
                    our Services (&quot;Member Content&quot;); and (iii) content that Mojito provides on and through our
                    Services (&quot;Our Content&quot;). In this agreement, &quot;content&quot; includes, without
                    limitation, all text, images, video, audio, or other material on our Services, including information
                    on users' profiles and in direct messages between users.</p>
                <h3>3a. YOUR CONTENT</h3>
                <p><strong><em>You are responsible for Your Content. Don't share anything that you wouldn't want others
                    to see, that would violate this Agreement, or that may expose you or us to legal
                    liability.</em></strong></p>
                <p>You are solely responsible and liable for Your Content, and, therefore, you agree to indemnify,
                    defend, release, and hold us harmless from any claims made in connection with Your Content.</p>
                <p>You represent and warrant to us that the information you provide to us or any other user is accurate,
                    including any information submitted through Facebook or other third-party sources (if applicable),
                    and that you will update your account information as necessary to ensure its accuracy.</p>
                <p>The content included on your individual profile should be relevant to the intended use of our
                    Services. You may not display any personal contact or banking information, whether in relation to
                    you or any other person (for example, names, home addresses or postcodes, telephone numbers, email
                    addresses, URLs, credit/debit card or other banking details). If you choose to reveal any personal
                    information about yourself to other users, you do so at your own risk. We encourage you to use
                    caution in disclosing any personal information online.</p>
                <p>Your individual profile will be visible to other people around the world, so be sure that you are
                    comfortable sharing Your Content before you post. You acknowledge and agree that Your Content may be
                    viewed by other users, and, notwithstanding these Terms, other users may share Your Content with
                    third parties. By uploading Your Content, you represent and warrant to us that you have all
                    necessary rights and licenses to do so and automatically grant us a license to use Your Content as
                    provided under Section 7 below.</p>
                <p>You understand and agree that we may monitor or review Your Content, and we have the right to remove,
                    delete, edit, limit, or block or prevent access to any of Your Content at any time in our sole
                    discretion. Furthermore, you understand and agree that we have no obligation to display or review
                    Your Content.</p>
                <h3>3b. MEMBER CONTENT</h3>
                <p><strong><em>While you will have access to Member Content, it is not yours and you may not copy or use
                    Member Content for any purpose except as contemplated by these Terms.</em></strong></p>
                <p>Other users will also share content on our Services. Member Content belongs to the user who posted
                    the content and is stored on our servers and displayed at the direction of that user.</p>
                <p>You do not have any rights in relation to Member Content, and you may only use Member Content to the
                    extent that your use is consistent with our Services' purpose of allowing use to communicate with
                    and meet one another. You may not copy the Member Content or use Member Content for commercial
                    purposes, to spam, to harass, or to make unlawful threats. We reserve the right to terminate your
                    account if you misuse Member Content.</p>
                <h3>3c. OUR CONTENT</h3>
                <p><strong><em>Mojito owns all other content on our Services.</em></strong></p>
                <p>Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, images, and
                    other intellectual property appearing on our Services is owned, controlled or licensed by us and
                    protected by copyright, trademark and other intellectual property law rights. All rights, title, and
                    interest in and to Our Content remains with us at all times.</p>
                <p>We grant you a limited license to access and use Our Content as provided under Section 6 below, and
                    we reserve all other rights.</p>
                <h3>4. INAPPROPRIATE CONTENT AND MISCONDUCT; REPORTING</h3>
                <p><strong><em>Mojito does not tolerate inappropriate content or behavior on our Services.</em></strong>
                </p>
                <p>We are committed to maintaining a positive and respectful Mojito community, and we do not tolerate any
                    inappropriate content or misconduct, whether on or off of the Services. We encourage you to report
                    any inappropriate Member Content or misconduct by other users. You can report a user directly
                    through the &quot;Report User&quot; link on a user's profile or in the messaging experience. You may
                    also email Mojito Customer Service at <a href='mailto:ricardo@ctonexus.com'>ricardo@ctonexus.com</a>.</p>
                <p>Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the Digital
                    Millennium Copyright Act 1998. To submit a complaint regarding Member Content that may constitute
                    intellectual property infringement, see Section 12 (Digital Millennium Copyright Act) below.</p>
                <h3>5. PRIVACY</h3>
                <p><strong><em>Privacy is important to us. We have a separate policy about it that you should read.</em></strong>
                </p>
                <p>For information about how Mojito and its affiliates collect, use, and share your personal data, please
                    read our Privacy Policy. By using our Services, you agree that we may use your personal data in
                    accordance with our Privacy Policy.</p>
                <h3>6. RIGHTS YOU ARE GRANTED BY MOJITO</h3>
                <p><strong><em>Mojito grants you the right to use and enjoy our Services, subject to these
                    Terms.</em></strong></p>
                <p>For as long as you comply with these Terms, Mojito grants you a personal, worldwide, royalty-free,
                    non-assignable, non-exclusive, revocable, and non-sublicensable license to access and use our
                    Services for purposes as intended by Mojito and permitted by these Terms and applicable laws.</p>
                <h3>7. RIGHTS YOU GRANT MOJITO</h3>
                <p><strong><em>You own all of the content you provide to Mojito, but you also grant us the right to use
                    Your Content as provided in this Agreement.</em></strong></p>
                <p>By creating an account, you grant to Mojito a worldwide, perpetual, transferable, sub-licensable,
                    royalty-free right and license to host, store, use, copy, display, reproduce, adapt, edit, publish,
                    translate, modify, and distribute Your Content, including any information you authorize us to access
                    from Facebook or other third-party source (if applicable), in whole or in part, and in any format or
                    medium currently known or developed in the future. Mojito's license to Your Content shall be
                    non-exclusive, except that Mojito's license shall be exclusive with respect to derivative works created
                    through use of our Services. For example, Mojito would have an exclusive license to screenshots of our
                    Services that include Your Content.</p>
                <p>In addition, so that Mojito can prevent the use of Your Content outside of our Services, you authorize
                    Mojito to act on your behalf with respect to infringing uses of Your Content taken from our Services by
                    other users or third parties. This expressly includes the authority, but not the obligation, to send
                    notices pursuant to 17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf if Your
                    Content is taken and used by third parties outside of our Services. Mojito is not obligated to take any
                    action with regard to use of Your Content by other users or third parties. Mojito's license to Your
                    Content is subject to your rights under applicable law (for example, laws regarding personal data
                    protection to the extent the content contains personal information as defined by those laws).</p>
                <p>In consideration for Mojito allowing you to use our Services, you agree that we, our affiliates, and our
                    third-party partners may place advertising on our Services. By submitting suggestions or feedback to
                    Mojito regarding our Services, you agree that Mojito may use and share such feedback for any purpose
                    without compensating you.</p>
                <p>You agree that Mojito may access, preserve, and disclose your account information, including Your
                    Content, if required to do so by law or upon a good faith belief that such access, preservation, or
                    disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce these Terms;
                    (iii) respond to claims that any content violates the rights of third parties; (iv) respond to your
                    requests for customer service; or (v) protect the rights, property or personal safety of the Company
                    or any other person.</p>
                <h3 className="red">8. PURCHASES AND AUTOMATICALLY RENEWING SUBSCRIPTIONS</h3>
                <p><strong><em>You will have the opportunity to purchase products and services from Mojito. If you purchase
                    a subscription, it will automatically renew - and you will be charged - until you
                    cancel.</em></strong></p>
                <p>Mojito may offer products and services for purchase through iTunes, Google Play, or other external
                    services authorized by Mojito (each, an &quot;External Service,&quot; and any purchases made thereon,
                    an &quot;External Service Purchase&quot;). Mojito may also offer products and services for purchase via
                    credit card or other payment processors on the Website or inside the App (&quot;Internal
                    Purchases&quot;). <span className="red">If you purchase a subscription, it will automatically renew until you cancel, in accordance with the terms disclosed to you at the time of purchase, as further described below.</span> If
                    you cancel your subscription, you will continue to have access to your subscription benefits until
                    the end of your subscription period, at which point it will expire.</p>
                <p>Because our Services may be utilized without a subscription, canceling your subscription does not
                    remove your profile from our Services. If you wish to fully terminate your membership, you must
                    terminate your membership as set forth in Section 9.</p>
                <h3 className="red">8a. EXTERNAL SERVICE PURCHASES AND SUBSCRIPTIONS</h3>
                        <p>External Service Purchases, including subscriptions, may be processed through the External
                            Service, in which case those purchases must be managed through your External Service
                            Account. Subscriptions automatically renew until you cancel.</p>
                        <p>When making a purchase on the Service, you may have the option to pay through an External
                            Service, such as with your Apple ID or Google account (&quot;your External Service
                            Account&quot;), and your External Service Account will be charged for the purchase in
                            accordance with the terms disclosed to you at the time of purchase and the general terms
                            applicable to your External Service Account. Some External Services may charge you sales
                            tax, depending on where you live, which may change from time to time.</p>
                        <p><span className="red">If your External Service Purchase includes an automatically renewing subscription, your External Service Account will continue to be periodically charged for the subscription until you cancel. After your initial subscription commitment period, and again after any subsequent subscription period, the subscription will automatically continue for the price and time period you agreed to when subscribing.</span>
                        </p>
                        <p><span className="red"><span className="underline">To cancel a subscription:</span> If you do not want your subscription to renew automatically, or if you want to change or terminate your subscription, you must log in to your External Service Account and follow instructions to manage or cancel your subscription, even if you have otherwise deleted your account with us or if you have deleted the App from your device. For example, if you subscribed using your Apple ID, cancellation is handled by Apple, not Mojito. To cancel a purchase made with your Apple ID, go to Settings &gt; iTunes &amp; App Stores &gt; [click on your Apple ID] &gt; View Apple ID &gt; Subscriptions, then find your Mojito subscription and follow the instructions to cancel. You can also request assistance at https://getsupport.apple.com. Similarly, if you subscribed on Google Play, cancellation is handled by Google. To cancel a purchase made through Google Play, launch the Google Play app on your mobile device and go to Menu &gt; My Apps &gt; Subscriptions, then find your Mojito subscription and follow the instructions to cancel. You can also request assistance at https://play.google.com. If you cancel a subscription, you may continue to use the cancelled service until the end of your then-current subscription term. The subscription will not be renewed when your then-current term expires.</span>
                        </p>
                        <p>If you initiate a chargeback or otherwise reverse a payment made with your External Service
                            Account, Mojito may terminate your account immediately in its sole discretion, on the basis
                            that you have determined that you do not want a Mojito subscription. In the event that your
                            chargeback or other payment reversal is overturned, please contact Customer Care. Mojito will
                            retain all funds charged to your External Service Account until you cancel your subscription
                            through your External Service Account. Certain users may be entitled to request a refund.
                            See Section 8d below for more information.</p>
                        <h3 className="red">8b. INTERNAL PURCHASES AND SUBSCRIPTIONS</h3>
                        <p><strong><em>Internal Purchases, including subscriptions, are processed using the Payment
                            Method you provide on the Website or App. Subscriptions automatically renew until you
                            cancel.</em></strong></p>
                        <p>If you make an Internal Purchase, you agree to pay the prices displayed to you for the
                            Services you've selected as well as any sales or similar taxes that may be imposed on your
                            payments (and as may change from time to time), and you authorize Mojito to charge the payment
                            method you provide (your &quot;Payment Method&quot;). Mojito may correct any billing errors or
                            mistakes even if we have already requested or received payment. If you initiate a chargeback
                            or otherwise reverse a payment made with your Payment Method, Mojito may terminate your account
                            immediately in its sole discretion, on the basis that you have determined that you do not
                            want a Mojito subscription. In the event that your chargeback or other payment reversal is
                            overturned, please contact Customer Care.</p>
                        <p><span className="red">If your Internal Purchase includes an automatically renewing subscription, your Payment Method will continue to be periodically charged for the subscription until you cancel. After your initial subscription commitment period, and again after any subsequent subscription period, your subscription will automatically continue for the price and time period you agreed to when subscribing, until you cancel.</span>
                        </p>
                        <p><span className="red">To cancel a subscription, log in to the Website and go to the Account section. If you cancel a subscription, you may continue to use the cancelled service until the end of your then-current subscription term. The subscription will not be renewed when your then-current term expires.</span>
                        </p>
                        <p>You may edit your Payment Method information by using the Settings tool and following the
                            link to let your upgrade expire. If a payment is not successfully processed, due to
                            expiration, insufficient funds, or otherwise, you remain responsible for any uncollected
                            amounts and authorize us to continue billing the Payment Method, as it may be updated. This
                            may result in a change to your payment billing dates.</p>
                        <p>In addition, you authorize us to obtain updated or replacement expiration dates and card
                            numbers for your credit or debit card as provided by your credit or debit card issuer. The
                            terms of your payment will be based on your Payment Method and may be determined by
                            agreements between you and the financial institution, credit card issuer, or other provider
                            of your chosen Payment Method. You agree that your payment to Mojito may be through CTO Nexus LLC.
                            Certain users may be entitled to request a refund. See Section 8d below for more information.</p>
                        <h3>8c. VIRTUAL ITEMS</h3>
                        <p><strong><em>Virtual items are non-refundable and subject to certain conditions.</em></strong>
                        </p>
                        <p>From time to time, you may have the opportunity purchase a limited, personal,
                            non-transferable, non-sublicensable, revocable license to use or access special limited-use
                            features including but not limited to tokens that are redeemable for other virtual items,
                            such as Mojito Coins (&quot;Virtual Item(s)&quot;) from Mojito. You may only
                            purchase Virtual Items from us or our authorized partners through our Services. Virtual
                            Items represent a limited license right governed by this Agreement, and, except as otherwise
                            prohibited by applicable law, no title or ownership in or to Virtual Items is being
                            transferred or assigned to you. This Agreement should not be construed as a sale of any
                            rights in Virtual Items.</p>
                        <p>Any Virtual Item balance shown in your account does not constitute a real-world balance or
                            reflect any stored value, but instead constitutes a measurement of the extent of your
                            license. Virtual Items do not incur fees for non-use; however, the license granted to you in
                            Virtual Items will terminate in accordance with the terms of this Agreement, on the earlier
                            of when Mojito ceases providing our Services, or your account is otherwise closed or
                            terminated.</p>
                        <p>Mojito, in its sole discretion, reserves the right to charge fees for the right to access or use
                            Virtual Items and/or may distribute Virtual Items with or without charge. Mojito may manage,
                            regulate, control, modify, or eliminate Virtual Items at any time, including taking actions
                            that may impact the perceived value or purchase price, if applicable, of any Virtual Items.
                            Mojito shall have no liability to you or any third party in the event that Mojito exercises any
                            such rights. The transfer of Virtual Items is prohibited, and you shall not sell, redeem, or
                            otherwise transfer Virtual Items to any person or entity. Virtual Items may only be redeemed
                            through our Services.</p>
                        <p>ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR SERVICES ARE FINAL AND
                            NON-REFUNDABLE. YOU ACKNOWLEDGE THAT MOJITO IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON,
                            AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN
                            ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.</p>
                        <h3>8d. REFUNDS</h3>
                        <p><strong><em>Generally, all purchases are nonrefundable. Special terms apply in Arizona,
                            California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode
                            Island, Wisconsin, and the EU or European Economic Area.</em></strong></p>
                        <p>Generally, all purchases are final and nonrefundable, and there are no refunds or credits for
                            partially used periods, except if the laws applicable in your jurisdiction provide for
                            refunds.</p>
                        <p><span className="underline">For subscribers residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode Island, and Wisconsin:</span>
                        </p>
                        <p>Your Right to Cancel - You may cancel your subscription, without penalty or obligation, at
                            any time prior to midnight of the third business day following the date you subscribed. In
                            the event that you die before the end of your subscription period, your estate shall be
                            entitled to a refund of that portion of any payment you had made for your subscription which
                            is allocable to the period after your death. In the event that you become disabled (such
                            that you are unable to use our Services) before the end of your subscription period, you
                            shall be entitled to a refund of that portion of any payment you had made for your
                            subscription which is allocable to the period after your disability by providing the company
                            notice in the same manner as you request a refund as described below.</p>
                        <p>Purchases of Virtual Items are FINAL AND NON-REFUNDABLE.</p>
                        <p>If any of the above apply to you and you subscribed using your Apple ID, your refund requests
                            are handled by Apple, not Mojito. To request a refund, please contact your External Service
                            directly; for example using your Apple device, go to Settings &gt; iTunes &amp; App
                            Stores &gt; [click on your Apple ID] &gt; View Apple ID &gt; Purchase History. Find the
                            transaction and select &quot;Report a Problem.&quot; You can also request a refund at
                            https://getsupport.apple.com. For any other purchase, please contact Mojito Customer Service
                            with your order number (see your confirmation email) by mailing or delivering a signed and
                            dated notice which states that you, the buyer, are canceling this agreement, or words of
                            similar effect. Please also include the email address or telephone number associated with
                            your account along with your order number. This notice shall be sent to:
                            <a href="mailto:ricardo@ctonexus.com"> ricardo@ctonexus.com</a></p>
                        <h3>9. ACCOUNT TERMINATION</h3>
                        <p><strong><em>If you no longer wish to use our Services, or if we terminate your account for
                            any reason, here's what you need to know.</em></strong></p>
                        <p>You can delete your account at any time by logging into the Website, going
                            to &quot;Help&quot;, clicking &quot;Delete account&quot;, and following the instructions to
                            cancel your membership. <strong>However, you will need to cancel / manage any External
                                Service Purchases through your External Service Account (e.g., iTunes, Google Play) to
                                avoid additional billing.</strong></p>
                        <p>Mojito reserves the right to investigate and, if appropriate, suspend or terminate your account
                            without a refund if you have violated these Terms, misused our Services, or behaved in a way
                            that Mojito regards as inappropriate or unlawful, on or off our Services. We reserve the right
                            to make use of any personal, technological, legal, or other means available to enforce the
                            Terms, at any time without liability and without the obligation to give you prior notice,
                            including, but not limited to, preventing you from accessing the Services.</p>
                        <p>If your account is terminated by you or by Mojito for any reason, these Terms continue and
                            remain enforceable between you and Mojito, and you will not be entitled to any refund for
                            purchases made. Your information will be maintained and deleted in accordance with our <a
                                href="/privacy">Privacy Policy</a>.</p>
                        <h3>10. NO CRIMINAL BACKGROUND OR IDENTITY VERIFICATION CHECKS</h3>
                        <p><strong><em>Mojito does not conduct criminal background or identity verification checks on its
                            users. Use your best judgment when interacting with others.</em></strong></p>
                        <p><strong>YOU UNDERSTAND THAT MOJITO DOES NOT CONDUCT CRIMINAL BACKGROUND OR IDENTITY VERIFICATION
                            CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS USERS.</strong> Mojito
                            MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT, IDENTITY, INTENTIONS, LEGITIMACY,
                            OR VERACITY OF USERS. MOJITO RESERVES THE RIGHT TO CONDUCT - AND YOU AUTHORIZE MOJITO TO CONDUCT -
                            ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES)
                            AT ANY TIME USING AVAILABLE PUBLIC RECORDS, AND YOU AGREE THAT ANY INFORMATION YOU PROVIDE
                            MAY BE USED FOR THAT PURPOSE. IF THE COMPANY DECIDES TO CONDUCT ANY SCREENING THROUGH A
                            CONSUMER REPORTING AGENCY, YOU HEREBY AUTHORIZE THE COMPANY TO OBTAIN AND USE A CONSUMER
                            REPORT ABOUT YOU TO DETERMINE YOUR ELIGIBILITY UNDER THESE TERMS.</p>
                        <p><strong>YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS.</strong> SEX
                            OFFENDER SCREENINGS AND OTHER TOOLS DO NOT GUARANTEE YOUR SAFETY AND ARE NOT A SUBSTITUTE
                            FOR FOLLOWING SENSIBLE SAFETY PRECAUTIONS. ALWAYS
                            USE YOUR BEST JUDGMENT AND TAKE APPROPRIATE SAFETY PRECAUTIONS WHEN COMMUNICATING WITH OR
                            MEETING NEW PEOPLE. COMMUNICATIONS RECEIVED THROUGH THE SERVICE, INCLUDING AUTOMATIC
                            NOTIFICATIONS SENT BY MOJITO, MAY RESULT FROM USERS ENGAGING WITH THE SERVICE FOR IMPROPER
                            PURPOSES, INCLUDING FRAUD, ABUSE, HARASSMENT, OR OTHER SUCH IMPROPER BEHAVIOR.</p>
                        <p>Though Mojito strives to encourage a respectful user experience, it is not responsible for the
                            conduct of any user on or off the Service. You agree to use caution in all interactions with
                            other users, particularly if you decide to communicate off the Service or meet in
                            person.</p>
                        <h3>11. DISCLAIMER</h3>
                        <p><strong><em>Mojito's Services are provided &quot;as is&quot; and we do not make, and cannot
                            make, any representations about the content or features of our Services.</em></strong></p>
                        <p>MOJITO PROVIDES OUR SERVICES ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS AND TO
                            THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                            IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO OUR SERVICES (INCLUDING ALL CONTENT
                            CONTAINED THEREIN), INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY
                            QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. MOJITO DOES NOT
                            REPRESENT OR WARRANT THAT (A) OUR SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE, (B)
                            ANY DEFECTS OR ERRORS IN OUR SERVICES WILL BE CORRECTED, OR (C) THAT ANY CONTENT OR
                            INFORMATION YOU OBTAIN ON OR THROUGH OUR SERVICES WILL BE ACCURATE. FURTHERMORE, MOJITO MAKES
                            NO GUARANTEES AS TO THE NUMBER OF ACTIVE USERS AT ANY TIME; USERS' ABILITY OR DESIRE TO
                            COMMUNICATE WITH OR MEET YOU, OR THE ULTIMATE COMPATIBILITY WITH OR CONDUCT BY USERS YOU
                            MEET THROUGH THE SERVICES.</p>
                        <p>MOJITO TAKES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS,
                            SENDS, OR RECEIVES THROUGH OUR SERVICES NOR DOES MOJITO TAKE ANY RESPONSIBILITY FOR THE
                            IDENTITY, INTENTIONS, LEGITIMACY, OR VERACITY OF ANY USERS WITH WHOM YOU MAY COMMUNICATION
                            THROUGH MOJITO. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF OUR SERVICES
                            IS ACCESSED AT YOUR OWN DISCRETION AND RISK. MOJITO IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR
                            COMPUTER HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, BUT
                            WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING,
                            FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
                            LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION.</p>
                        <h3>12. DIGITAL MILLENNIUM COPYRIGHT ACT</h3>
                        <p><strong><em>We take copyright infringement very seriously. We ask you to help us to ensure we
                            address it promptly and effectively.</em></strong></p>
                        <p>Mojito has adopted the following policy towards copyright infringement in accordance with the
                            Digital Millennium Copyright Act (the &quot;DMCA&quot;). If you believe any Member Content
                            or Our Content infringes upon your intellectual property rights, please submit a
                            notification alleging such infringement (&quot;DMCA Takedown Notice&quot;) including the
                            following:</p>
                        <ol>
                            <li>A physical or electronic signature of a person authorized to act on behalf of the owner
                                of an exclusive right that is allegedly infringed;
                            </li>
                            <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple
                                copyrighted works at a single online site are covered by a single notification, a
                                representative list of such works;
                            </li>
                            <li>Identification of the material claimed to be infringing or to be the subject of
                                infringing activity and that is to be removed or access disabled and information
                                reasonably sufficient to permit the service provider to locate the material;
                            </li>
                            <li>Information reasonably sufficient to permit the service provider to contact you, such as
                                an address, telephone number, and, if available, an electronic mail;
                            </li>
                            <li>A statement that you have a good faith belief that use of the material in the manner
                                complained of is not authorized by the copyright owner, its agent, or the law; and
                            </li>
                            <li>A statement that, under penalty of perjury, the information in the notification is
                                accurate and you are authorized to act on behalf of the owner of the exclusive right
                                that is allegedly infringed.
                            </li>
                        </ol>
                        <p>Any DMCA Takedown Notices should be sent to
                            <a href="mailto:ricardo@ctonexus.com"> ricardo@ctonexus.com</a>.</p>
                        <p>Mojito will terminate the accounts of repeat infringers.</p>
                        <h3>13. ADS AND THIRD-PARTY CONTENT</h3>
                        <p><strong><em>Like many subscription-based services, there are ads on our
                            websites.</em></strong></p>
                        <p>Our Services may contain advertisements and promotions offered by third parties and links to
                            other websites or resources. Mojito may also provide non-commercial links or references to
                            third parties within its content. Mojito is not responsible for the availability (or lack of
                            availability) of any external websites or resources or their content. Furthermore, Mojito is
                            not responsible for, and does not endorse, any products or services that may be offered by
                            third-party websites or resources. If you choose to interact with the third parties made
                            available through our Services, such party's terms will govern their relationship with you.
                            Mojito is not responsible or liable for such third parties' terms or actions.</p>
                        <h3>14. LIMITATION OF LIABILITY.</h3>
                        <p><strong><em>Mojito's liability is limited to the maximum extent by applicable law.</em></strong>
                        </p>
                        <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MOJITO, ITS AFFILIATES,
                            EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL,
                            EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF
                            PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR
                            OTHER INTANGIBLE LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS
                            OR USE THE SERVICES, (II) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON,
                            THROUGH, OR FOLLOWING USE OF THE SERVICES; OR (III) UNAUTHORIZED ACCESS, USE, OR ALTERATION
                            OF YOUR CONTENT, EVEN IF MOJITO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
                            EVENT WILL MOJITO'S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED
                            THE AMOUNT PAID, IF ANY, BY YOU TO MOJITO FOR THE SERVICES WHILE YOU HAVE AN ACCOUNT.</p>
                        <p>THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14 SHALL APPLY EVEN IF YOUR
                            REMEDIES UNDER THIS AGREEMENT FAIL WITH RESPECT TO THEIR ESSENTIAL PURPOSE.</p>
                        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR
                            ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</p>
                        <h3>15. DISPUTE RESOLUTION</h3>
                        <p><strong><em>In the unlikely event that we have a legal dispute, here is what you need to
                            know.</em></strong></p>
                        <p>If you are dissatisfied with our Services for any reason, please contact Mojito Customer Service
                            first so that we can try to resolve your concerns without the need of outside assistance. If
                            you choose to pursue a claim against Mojito, these terms will apply.</p>
                        <h3>15a. ARBITRATION, CLASS-ACTION WAIVER, AND JURY WAIVER</h3>
                        <p><strong><em>If you pursue a legal claim against Mojito, you agree to arbitration (with limited
                            exceptions).</em></strong></p>
                        <p>Except for users residing within the EU or European Economic Area, and where prohibited by
                            law:</p>
                        <ol>
                            <li>The exclusive means of resolving any dispute or claim arising out of or relating to this
                                Agreement (including any alleged breach thereof) or our Services shall be BINDING
                                ARBITRATION administered by JAMS under the JAMS Streamlined Arbitration
                                Rules &amp; Procedures. The one exception to the exclusivity of arbitration is that either
                                party has the right to bring an individual claim against the other in a small-claims
                                court of competent jurisdiction, or, if filed in arbitration, the responding party may
                                request that the dispute proceed in small claims court if the party's claim is within
                                the jurisdiction of the small claims court. If the responding party requests to proceed
                                in small claims court before the appointment of the arbitrator, the arbitration shall be
                                administratively closed, and if requested after the appointment of the arbitrator, the
                                arbitrator shall determine if the dispute should be decided in arbitration or if the
                                arbitration should be administratively closed and decided in small claims court. Whether
                                you choose arbitration or small-claims court, you may not under any circumstances
                                commence or maintain against the Company any class action, class arbitration, or other
                                representative action or proceeding.
                            </li>
                            <li>By using our Services in any manner, you agree to the above arbitration agreement. In
                                doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between
                                you and the Company (except for matters that may be taken to small-claims court). YOU
                                ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your
                                rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY, and the
                                arbitrator shall determine all issues regarding the arbitrability of the dispute. You
                                are entitled to a fair hearing before the arbitrator. The arbitrator can grant any
                                relief that a court can, but you should note that arbitration proceedings are usually
                                simpler and more streamlined than trials and other judicial proceedings. Decisions by
                                the arbitrator are enforceable in court and may be overturned by a court only for very
                                limited reasons.
                            </li>
                            <li>Any proceeding to enforce this arbitration agreement, including any proceeding to
                                confirm, modify, or vacate an arbitration award, may be commenced in any court of
                                competent jurisdiction. In the event that this arbitration agreement is for any reason
                                held to be unenforceable, any litigation against the Company (except for small-claims
                                court actions) may be commenced only in the federal or state courts located in New York
                                County, New York. You hereby irrevocably consent to the jurisdiction of those courts for
                                such purposes.
                            </li>
                            <li>The online dispute settlement platform of the European Commission is available under
                                http://ec.europa.eu/odr. Mojito does not take part in dispute settlement procedures in
                                front of a consumer arbitration entity for users residing in the EU or European Economic
                                Area.
                            </li>
                        </ol>
                        <h3>15b. GOVERNING LAW</h3>
                        <p><strong><em>Texas law and the Federal Arbitration Act will apply if there is a dispute
                            (except in the EU and where prohibited by law).</em></strong></p>
                        <p>Except for users residing in the EU or European Economic Area or elsewhere where our
                            arbitration agreement is prohibited by law, the laws of Texas, U.S.A., excluding Texas's
                            conflict of laws rules, will apply to any disputes arising out of or relating to this
                            Agreement or our Services. Notwithstanding the foregoing, the Arbitration Agreement in
                            Section 15a above shall be governed by the Federal Arbitration Act. For the avoidance of
                            doubt, the choice of Texas governing law shall not supersede any mandatory consumer
                            protection legislation in such jurisdictions.</p>
                        <h3>15c. VENUE</h3>
                        <p><strong><em>Any claims that are not submitted to arbitration for any reason must be litigated
                            in New York County, New York (except for claims brought in small claims court, and in the EU or
                            where prohibited by law).</em></strong></p>
                        <p>Except for users residing in the EU or European Economic Area, who may bring claims in their
                            country of residence in accordance with applicable law, and except for claims that may be
                            properly brought in a small claims court of competent jurisdiction in the county or other
                            jurisdiction in which you reside or in New York County, New York, all claims arising out of or
                            relating to this Agreement, to our Services, or to your relationship with Mojito that for
                            whatever reason are not submitted to arbitration will be litigated exclusively in the
                            federal or state courts of New York County, New York, U.S.A. You and Mojito consent to the exercise
                            of personal jurisdiction of courts in the State of Texas and waive any claim that such
                            courts constitute an inconvenient forum.</p>
                        <h3>16. INDEMNITY BY YOU</h3>
                        <p><strong><em>You agree to indemnify Mojito if a claim is made against Mojito due to your
                            actions.</em></strong></p>
                        <p>You agree, to the extent permitted under applicable law, to indemnify, defend, and hold
                            harmless Mojito, our affiliates, and their and our respective officers, directors, agents, and
                            employees from and against any and all complaints, demands, claims, damages, losses, costs,
                            liabilities, and expenses, including attorney's fees, due to, arising out of, or relating in
                            any way to your access to or use of our Services, Your Content, Your conduct toward other
                            users, or your breach of this Agreement.</p>
                        <h3>17. ACCEPTANCE OF TERMS</h3>
                        <p><strong><em>By using our Services, you accept the Terms of this Agreement.</em></strong></p>
                        <p>By using our Services, whether through a mobile device, mobile application, or computer, you
                            agree to be bound by (i) these Terms, which we may amend from time to time, (ii) our <a
                                href="/privacy">Privacy Policy</a>, (iii) the <a href="/community-guidelines">Community Guidelines</a>, and
                            (iv) any Additional Terms Upon Purchase. If you do not accept and agree to be bound by all
                            of the terms of this Agreement, please do not use our Services.</p>
                        <p>The section headings and summaries contained herein are inserted for convenience only and
                            shall not be considered in interpreting any term or provision hereof. All pronouns and any
                            variations thereof shall be deemed to refer to the masculine, feminine, neuter, singular or
                            plural as the identity of the entities or persons referred to any require. Any word both
                            capitalized and uncapitalized will be deemed to have the same meaning.</p>
                        <h3>18. ENTIRE AGREEMENT</h3>
                        <p><strong><em>This Agreement supersedes any previous agreements or
                            representations.</em></strong></p>
                        <p>These Terms, with the <a href="/privacy">Privacy Policy</a>, <a href="/community-guidelines">Community
                            Guidelines</a>, and any Additional Terms Upon Purchase, contain the entire agreement between
                            you and Mojito regarding the use of our Services. The Terms supersede all previous agreements,
                            representations, and arrangements between us, written or oral. If any provision of these
                            Terms is held invalid, illegal, or otherwise unenforceable, the remainder of the Terms shall
                            continue in full force and effect. The failure of the Company to exercise or enforce any
                            right or provision of these Terms shall not constitute a waiver of such right or provision.
                            You agree that your Mojito account is non-transferable and all of your rights to your account
                            and its content terminate upon your death, unless otherwise provided by law. Any rights and
                            licenses granted hereunder, may not be transferred or assigned by you, but may be assigned
                            by us without restriction. No agency, partnership, joint venture, fiduciary or other special
                            relationship or employment is created as a result of these Terms, and you may not make any
                            representations on behalf of or bind Mojito in any manner.</p>
                        <h3>19. SPECIAL STATE TERMS</h3>
                        <p><strong><em>Special terms apply in Arizona, California, Connecticut, Illinois, Iowa,
                            Minnesota, New York, North Carolina, Ohio, Rhode Island, Wisconsin</em></strong></p>
                        <p><span className="underline">For subscribers residing in New York:</span></p>
                        <ul>
                            <li>The Services do not guarantee any number of &quot;referrals&quot; - rather, the
                                functionality of the Services is such that the subscriber can view as many profiles as
                                he/she would like;
                            </li>
                            <li>Upon notice sent to <a href='mailto:ricardo@ctonexus.com'>ricardo@ctonexus.com</a>, subscribers may place their subscription on hold for up to one year;
                            </li>
                            <li>How your information is used and how you may access your information is set forth in
                                our <Link to="/privacy">Privacy Policy</Link>;
                            </li>
                            <li>You may review the New York Dating Service Consumer Bill of Rights
                                <a href="https://www.nysenate.gov/legislation/laws/GBS/394-C"> here</a>;
                            </li>
                        </ul>
                        <p><span className="underline">For subscribers residing in North Carolina:</span></p>
                        <ul>
                            <li>You may review the North Carolina Buyer's Rights here.</li>
                        </ul>
                        <p><span className="underline">For subscribers residing in Illinois, New York, North Carolina, and Ohio:</span>
                        </p>
                        <ul>
                            <li>Our Services are widely available in the United States - if you believe that you have
                                moved outside a location where we provide the Services, please contact us at
                                <a href='mailto:ricardo@ctonexus.com'> ricardo@ctonexus.com</a>, and we will
                                work with you to provide alternative services or a refund.<br />
                                    For subscribers residing in Arizona, California, Connecticut, Illinois, Iowa,
                                    Minnesota, New York, North Carolina, Ohio, Rhode Island, and Wisconsin:
                            </li>
                        </ul>
                        <p><strong>Your Right to Cancel - You may cancel your subscription, without penalty or
                            obligation, at any time prior to midnight of the third business day following the date you
                            subscribed.</strong> In the event that you die before the end of your subscription period,
                            your estate shall be entitled to a refund of that portion of any payment you had made for
                            your subscription which is allocable to the period after your death. In the event that you
                            become disabled (such that you are unable to use our Services) before the end of your
                            subscription period, you shall be entitled to a refund of that portion of any payment you
                            had made for your subscription which is allocable to the period after your disability by
                            providing the company notice in the same manner as you request a refund as described above
                            in Section 8.</p>
                </div>
            </IonContent>
            </IonPage>
        );
    }
}
