import * as React from 'react';
import {trackEvent} from "../../shared/Utilities";
import AppStoreIcon from "../../assets/HomePage/download-on-app-store.svg";
import {useLocation} from "react-router";



export const AppStoreButton: React.FC = () => {

    const location = useLocation();
    const searchQuery = location.search;
    //const searchQuery = location.search ? `${location.search}&pt=119666691&ct=mojito-dating&mt=8` : '?pt=119666691&ct=mojito-dating&mt=8';
    const appStoreUrl = `https://apps.apple.com/app/apple-store/id1497407546${searchQuery}`;

    return(
        <a href={appStoreUrl} target="_blank" onClick={()=>trackEvent("AppStoreButtonClick")}>
            <img style={{width: "140px"}} src={AppStoreIcon} alt="App Store" />
        </a>
    )
}