import { Redirect, Route } from 'react-router-dom';
import {
    IonRouterOutlet,
} from '@ionic/react';
import * as React from "react";
import {LoggedOutHomePage} from "../../pages/LoggedOutHomePage/LoggedOutHomePage";
import PrivacyPage from "../../pages/Legal/PrivacyPage";
import TermsOfUse from "../../pages/Legal/TermsOfUse";
import CommunityGuidelines from "../../pages/Legal/CommunityGuidelines";
import {LoginPage} from "../../pages/LoginRegisterPage/LoginPage";
import {ForgotPasswordPage} from "../../pages/ForgotPasswordPage/ForgotPasswordPage";
import {PasswordResetPage} from "../../pages/PasswordResetPage/PasswordResetPage";


export const LoggedOutNavigation: React.FC = () => (
      <IonRouterOutlet>
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />

          <Route exact path="/reset-password" component={PasswordResetPage} />

          <Route exact path="/login" component={LoginPage} />

          <Route exact path="/privacy" component={PrivacyPage} />

          <Route exact path="/terms-of-use" component={TermsOfUse} />

          <Route exact path="/community-guidelines" component={CommunityGuidelines} />

          <Route exact path="/" component={LoggedOutHomePage}  />
          <Route>
              <Redirect to="/" exact />
          </Route>
      </IonRouterOutlet>
);
