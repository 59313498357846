import * as React from "react";
import "./LoggedOutHomePage.scss";
import {LoggedOutHomePageWeb} from "./LoggedOutHomePageWeb";
import {LoggedOutHomePageApp} from "./LoggedOutHomePageApp";
import {IS_BROWSER} from "../../shared/Constants";

interface Props {

}

export const LoggedOutHomePage: React.FC<Props> = () => {

    return (
      <div className="logged-out-home-page">
          {IS_BROWSER ? <LoggedOutHomePageWeb /> : <LoggedOutHomePageApp />}
      </div>
    )
}
