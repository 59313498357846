import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Mixpanel from "mixpanel-browser";
import {REACT_APP_GOOGLE_ADWORDS_ID, REACT_APP_GOOGLE_ANALYTICS_ID, REACT_APP_MIXPANEL_TOKEN} from "./shared/Constants";

if(REACT_APP_GOOGLE_ANALYTICS_ID) {
    // @ts-ignore
    window.gtag('config', REACT_APP_GOOGLE_ANALYTICS_ID, { 'groups': 'analytics' });
}
if(REACT_APP_GOOGLE_ADWORDS_ID) {
    // @ts-ignore
    window.gtag('config', REACT_APP_GOOGLE_ADWORDS_ID, { 'groups': 'adwords' });
}

if(REACT_APP_MIXPANEL_TOKEN) {
    Mixpanel.init(REACT_APP_MIXPANEL_TOKEN);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
